.carousel {
  &-inner {
    &-img{
      max-height: 800px;
    }
    img {
      margin: auto;
      padding: 1em 0 15px 0;
      max-width: 80%;
    }
  }
  &-button{
    border-radius: 50px;
    background-color: #EAF7FD;
    padding: 7px 18px;
    white-space: nowrap;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    text-decoration: none;
    color: #3F485D;
    &:hover{
      background-color: #212529;
      color: #fff;
    }
    &.active{
      background-color: #0078D2;
      color: #fff;
    }
  }
  &-indicators {
    text-align: center;
    list-style-position: inside;
    max-width: 100%;
    position: relative;
    margin-right: 0;
    margin-left: 0;
    flex-flow: row wrap;
    margin-top: 8px;
  }
}

#carouselPuedaImages .carousel-control {
  &-prev,
  &-next {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: rgb(0, 120, 210);
  }
}
#carouselPuedaImages .carousel-control,
#carouselPuedaVideos .carousel-control {
  &-next {
      right: -11em;
  }
  &-prev {
      left: -11em;
  }
}
