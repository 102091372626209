@media screen and (max-width: 980px){
	.contenedor-de-botones {
    align-items: center;
    padding-right: 0px;
		.button-link {
	    width: 410px;
		}
	}
	.bg-full-lightgrey{
		&:before {
			left: 50%;
			transform: translateX( -50%);
		}
		&:before {
			left: 50%;
			transform: translateX( -50%);
		}
	}
	.margin-body{
		margin-left:1.5%;
	}
	.info-grid {
		&-ro{
			padding: 0;
			display: flex;
			justify-content: center;
			margin-left: 20px;
		}
		&-item{
			border: 0px solid #fff;
			border-radius: 10px;
			margin-left: -20px;
			margin-right: -20px;
			margin-bottom: 10px;
		}
	}
	.timeline {
		border-left: 1px dashed #0078D2;
		border-right: none;
		line-height: 24px;
		height: 97%;
		top: 52px;
		left: 3px;
		&::after {
			top: -9px;
			left: -7px
		}
		&::before {
			bottom: -9px;
			left: -7px
		}
		&-content-line{
			right: 8.3%;
		}
		/* Years - Resolucion mobile*/
		&-years {
			border: 1px solid #0078D2;
		}
	}
	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
		margin-left: 0px;
		margin-right: 0px;
		padding: 0px 6% 0 6%;
		> * {
			box-sizing: border-box;
			padding-left: 0px;
			padding: 50px 0 0 0;
		}
		&.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		&.aln-left {
			justify-content: flex-start;
		}

		&.aln-center {
			justify-content: center;
		}

		&.aln-right {
			justify-content: flex-end;
		}

		&.aln-top {
			align-items: flex-start;
		}

		&.aln-middle {
			align-items: center;
		}

		&.aln-bottom {
			align-items: flex-end;
		}
		> .imp-medium {
			order: -1;
		}

		> .col-1-medium {
			width: 8.33333%;
		}

		> .off-1-medium {
			margin-left: 8.33333%;
		}

		> .col-2-medium {
			width: 16.66667%;
		}

		> .off-2-medium {
			margin-left: 16.66667%;
		}

		> .col-3-medium {
			width: 25%;
		}

		> .off-3-medium {
			margin-left: 25%;
		}

		> .col-4-medium {
			width: 33.33333%;
		}

		> .off-4-medium {
			margin-left: 33.33333%;
		}

		> .col-5-medium {
			width: 41.66667%;
		}

		> .off-5-medium {
			margin-left: 41.66667%;
		}

		> .col-6-medium {
			width: 50%;
		}

		> .off-6-medium {
			margin-left: 50%;
		}

		> .col-7-medium {
			width: 58.33333%;
		}

		> .off-7-medium {
			margin-left: 58.33333%;
		}

		> .col-8-medium {
			width: 66.66667%;
		}

		> .off-8-medium {
			margin-left: 66.66667%;
		}

		> .col-9-medium {
			width: 75%;
		}

		> .off-9-medium {
			margin-left: 75%;
		}

		> .col-10-medium {
			width: 83.33333%;
		}

		> .off-10-medium {
			margin-left: 83.33333%;
		}

		> .col-11-medium {
			width: 91.66667%;
		}

		> .off-11-medium {
			margin-left: 91.66667%;
		}

		> .col-12-medium {
			width: 100%;
		}

		> .off-12-medium {
			margin-left: 100%;
		}
		&.gtr-0 {
			margin-top: 0px;
			margin-left: 0px;
			> * {
				padding: 0px 0 0 0px;
			}
			&.gtr-uniform {
				margin-top: 0px;
				> * {
					padding-top: 0px;
				}
			}
		}

		&.gtr-25 {
			margin-top: -12.5px;
			margin-left: -12.5px;
			> * {
				padding: 12.5px 0 0 12.5px;
			}
			&.gtr-uniform {
				margin-top: -12.5px;
				> * {
					padding-top: 12.5px;
				}
			}
		}


		&.gtr-50 {
			margin-top: -25px;
			margin-left: -25px;
			> * {
				padding: 25px 0 0 25px;
			}
			&.gtr-uniform {
				margin-top: -25px;
				> * {
					padding-top: 25px;
				}
			}
		}

		&.gtr-uniform {
			margin-top: -50px;
			> * {
				padding-top: 50px;
			}
		}


		&.gtr-150 {
			margin-top: -75px;
			margin-left: -75px;
			> * {
				padding: 75px 0 0 75px;
			}
			&.gtr-uniform {
				margin-top: -75px;
				> * {
					padding-top: 75px;
				}
			}
		}


		&.gtr-200 {
			margin-top: -100px;
			margin-left: -100px;
			> * {
				padding: 100px 0 0 100px;
			}

			&.gtr-uniform {
				margin-top: -100px;
				> * {
					padding-top: 100px;
				}
			}
		}
	}
	.timeline {
		height: 98%;
		&-years {
			left: 5%;
		}
	}
	.redirect-btn-container-la-accesibilidad-web{
		position: relative;
		left: 3em;
	}
	.bars{
		width: 90%;
	}
	@-moz-document url-prefix(){
		.bars img{
			width: 106%;
		}
	}
	.container {
		width: calc(100% - 100px);
		&-lightblue-background li {
			font-size: 24px;
			line-height: 30px;
		}
	}
	.card-info .map-info-text .blue-text, .card-info .map-info-text .lightblue-text {
		font-size: 27px;
		line-height: 32px;
	}
	.timeline-col-11{
		max-width: min-content;
		display: contents
	}
	.map-container {
		.row {
			display: block;
			&-9 {
				display: flex;
			}
			&-3 {
				display: flex;
			}
		}
	}
	.card-info {
		.col{
			padding: 2em;
			align-content: center;
			&-7{
				display: inline;
			}
			&-5{
				display: unset;
			}
		}
	}
	#mapa{
		height:279px;
		width:127px;
	}
	.zoom {
		&-btn{
			margin-left: 0em;
			margin-right: 0.3em;
			width: 2.5em;
			height: 2.5em;
			border: 0.1em solid #5d6884;
			font-size: 20px;
		}
		&-buttons-container{
			display: flex;
			flex-direction: row;
			margin: 0 0 20px;
		}
	}
	html, body {
		overflow-x: hidden;
	}
	h1 {
		font-family: 'Work Sans';
		margin-left: 0em;
		font-weight: 900;
		font-size: 73.3px;
		line-height: 69px;
		margin-bottom: 0.5em;
		text-align: left;
	}
	h2{
		font-family: 'Work Sans';
		font-size: 50.53px;
		margin-top: 2.5em;
		font-weight: 600;
		margin-bottom: 0.5em;
		line-height: 47px;
		text-align: left;
	}
	p{
		font-size: 27px;
		margin-right: 2em;
		line-height: 37px;
	}
	.subtitle{
		margin-top: 1em;
		font-size: 67.3px;
		line-height: 83px;
		font-weight: 600;
		margin-bottom: 0.5em;
		margin-left: -18px;
	}
	.col {
		&-12{
			padding-left: 0;
			padding-right: 1em;
		}
		&-11{
			width: 100%;
			max-width: max-content;
			flex: 0 0 97%;
			padding-top: 0;
		}
		&-1 .not-vissible-col{
			display: none;
		}
	}
	.not-vissible-col-redirection-buttons{
		display: none;
	}
	.container {
		&-lightblue-background{
			margin-right: 0;
			margin-left: 0;
		}
		&-lightblue-background ul{
			font-size: 27px;
		}
	}
	.card-info {
		p{
			font-size: 27px;
			line-height: 32px;
		}
		li{
			font-size: 27px;
			line-height: 32px;
		}
	}
	.highlight {
		&-text-simplelist li{
			font-size: 27px;
			padding: 0;
			line-height: 32px;
		}
		&-text-container{
			margin-bottom: 1em;
			margin-right: 2em;
		}
	}
	ol li{
		font-size: 27px;
		line-height: 32px;
	}
	.info-container {
		button{
			width: 40%;
			font-size: larger;
		}
		p{
			margin-top: 1em;
		}
	}
	.container-lightblue-background{
		margin-bottom: 2.5em;
		padding: 1em;
	}
	.offset-xl-1 .info-container{
		max-width: 98.8%;
	}
	.highlight-text {
		padding: 0.1em;
		margin-top: 1em;
		margin-bottom: 1.3em;
	}
	.printer-button-container {
		display: flex;
		padding-top: 1em;
		padding-bottom: 0.5em;
		flex-direction: row;
		align-self: center;
		align-content: center;
		justify-content: center;
	}
	.redirect {
		&-buttons-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-content: center;
			margin-top: 0;
		}
		&-button-container {
			flex-direction: column;
			padding-top: 1em;
			padding-bottom: 0.5em;
			padding-left: 0;
			padding-right: 0;
			margin-right: 0.5em;
			margin-left: 0.5em;
			width: 15em;
			display: flex;
			/* align-self: center; */
			height: unset;
			background-color: transparent;
		}
		&-button-label{
			font-size: 48px;
			line-height: 56px;
		}
	}
	.button  {
		&-la-accesibilidad-web{
			padding-right: 17em;
			padding-left: 17em;
			padding-top: 2em;
			padding-bottom: 2em;
			width: unset;
			-moz-box-sizing: unset;
		}
		&-beneficios-y-recomendaciones-2-btns,
		&-como-se-investigo-2-btns,
		&-beneficios-y-recomendaciones,
		&-como-se-investigo{
			padding-right: 18.6em;
			padding-left: 18.6em;
			padding-top: 2em;
			padding-bottom: 2em;
			width: unset;
			-moz-box-sizing: unset;
		}
		&-la-accesibilidad-web-2-btns{
			padding-right: 17em;
			padding-left: 17em;
			padding-top: 2em;
			padding-bottom: 2em;
			width: unset;
			-moz-box-sizing: unset;
		}
	}
	.redirect-buttons-container-2-btns {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		margin-top: 0;
		width: unset;
		-moz-box-sizing: unset;
	}
	#footer {
		font-size: 23px;
		line-height: 21px;
		ul {
			margin-left: 0em;
			margin-right: 0em;
		}

		div ul.footer-list-container {
			padding: 0px;
		}

		.logo {
			width: 160px;
		}

		div {
			ul li {
				width: 40%;
				display: -moz-inline-table;
				display: inline-table;
				padding: 15px 0 15px 0;
				margin: 5px;
				text-align: left;
				letter-spacing: 0px;
				&:first-child {
					width: 40%;
				}
				&:last-child {
					width: 100%;
					display: -moz-inline-stack;
					display: inline-block;
					margin: 0;
					padding: 20px;
					background-color: #f2f4f9;
					line-height: 21px;
				}
			}
		}
		.copyright {
			text-align: center;
			background-color: #eaecf6;
		}

		p {
			margin: 0.2em;
			font-size: 16px;
			text-align: center;
		}
		li {
			font-size: 23px;
		}
		div {
			ul {
				&.footer-list-container li {
					padding-left: 3%;
					padding-right: 2%;
				}
				li {
					width: 50%;
					display: -moz-inline-table;
					display: inline-table;
					padding: 15px 0 15px 0;
					margin: 5px;
					text-align: left;
					letter-spacing: 0px;
					&:first-child {
						width: 40%;
					}
					&:last-child {
						width: 100%;
						display: -moz-inline-stack;
						display: inline-block;
						margin: 0;
						padding: 20px 64px;
					}
				}
			}
		}
	}
	.copyright p span{
		font-size: 23px;
	}
	.inner-subtitle {
		margin-left: -4px;

	}
	#main {
		margin: 1em 0 1em 0;
		border-top-width: 0;
		padding-top: 0;
		.body-container {
			width: 100%;
			margin: 0;
		}
	}
	.body-container {
		width: 100%;
		margin: 0;
		.timeline-row {
			flex: 0 0 91.66667%;
		}
	}
	.col-1 .vissible-col{
		display: flex;
		flex-direction: row;
		width: 25%;
		max-width: none;
	}
	.box br {
		display: block;
	}
	br {
		display: block;
	}
	@media screen and (-webkit-min-device-pixel-ratio:0) {
		.bars br {
			display: none;
		}
	}
	#header {
		display: none;
	}
	#banner {
		padding: calc(14em + 44px) 0 14em 0;
	}
	#nav {
		display: none;
	}
	.sidebar {
		border-top: solid 2px #e7eae8;
		padding-top: 50px;
	}
	body {
		padding-top: 110px;
	}

	#page-wrapper {
		-moz-backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		backface-visibility: hidden;
		-moz-transition: -moz-transform 0.5s ease;
		-webkit-transition: -webkit-transform 0.5s ease;
		-ms-transition: -ms-transform 0.5s ease;
		transition: transform 0.5s ease;
		padding-bottom: 1px;
	}

	#titleBar {
		-moz-backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		backface-visibility: hidden;
		-moz-transition: -moz-transform 0.5s ease;
		-webkit-transition: -webkit-transform 0.5s ease;
		-ms-transition: -ms-transform 0.5s ease;
		transition: transform 0.5s ease;
		display: block;
		height: 44px;
		left: 0;
		position: absolute;
		top: 0;
		width: 96%;
		z-index: 10001;
		padding: 0 5%;
		.toggle {
			text-decoration: none;
			position: absolute;
			right: 0;
			top: 0;
			width: 100px;
			height: 80px;
			z-index: 1001;
			&:after {
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-family: 'FontAwesome';
				font-style: normal;
				font-variant: normal;
				font-weight: lighter;
				font-size: 330%;
				text-align: center;
				text-rendering: auto;
				text-transform: none !important;
				text-decoration: none;
				position: relative;
				left: 30%;
				top: 5%;
				content: '\f0c9';
				color: #000;
				line-height: 2em;
			}
		}
		.logo {
			position: relative;
			top: 50%;
			width: 145px;
			left: 25px;
		}
		.navPanel-visible #titleBar .toggle:after {
			content: '\f00d';
		}
	}



	#navPanel {
		-moz-backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		backface-visibility: hidden;
		-moz-transform: translateX(275px);
		-webkit-transform: translateX(275px);
		-ms-transform: translateX(275px);
		transform: translateX(275px);
		-moz-transition: -moz-transform 0.5s ease;
		-webkit-transition: -webkit-transform 0.5s ease;
		-ms-transition: -ms-transform 0.5s ease;
		transition: transform 0.5s ease;
		display: block;
		height: 100%;
		right: 0px;
		overflow-y: auto;
		position: fixed;
		top: 0;
		width: 275px;
		z-index: 10000;
		background-color: #ffff;
		background-size: auto, 100% 100%;
		font-family: 'Work Sans', sans-serif;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 1.25em;
		nav {
			position: relative;
			z-index: 1000;
		}

		.link {
			display: block;
			color: inherit;
			text-decoration: none;
			text-align: right;
			padding: 0 1em 0 1em;
			color: #5d6884;
			font-size: 0.8em;
			line-height: inherit;
			display: inline-table;
			width: 100%;
			vertical-align: middle;
			margin-bottom: 20px;
			&:first-of-type {
				margin-top: 20px;
			}

			&:first-child {
				border-top: 0;
			}
		}


		a.active {
			color: #0078D2;
		}

		.indent {
			&-1 {
				display: inline-block;
				width: 1em;
			}

			&-2 {
				display: inline-block;
				width: 2em;
			}

			&-3 {
				display: inline-block;
				width: 3em;
			}

			&-4 {
				display: inline-block;
				width: 4em;
			}

			&-5 {
				display: inline-block;
				width: 5em;
			}
		}

		.depth-0 {
			color: #5d6884;
		}
	}



	body.navPanel-visible #navPanel {
		-moz-transform: translateX(0);
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}

	.pueda {
		h2 {
			padding: 0px;
		}
		.bg-full-darkgrey h2 {
			padding: 30px 4.7% 60px 5.7%;
		}
	}


	.body-container {
		.puedapueda {
			padding-left: 72px;
			padding-right: 59px;
		}
		.puedaExcept {
			padding-left: 70px;
			padding-right: 72px;
		}
	}
	.scrollspy {
    margin-bottom: 5px;
		&-row.buttons {
			margin-left: 7%;
		}
		&-title {
			margin-left: 13.3%;
			margin-right: 303px;
		}
	}
	.nav-pills {
		margin-left: 9%;
	}
	#banner {
		background-image: url('/assets/images/Accesibilidad_ADC_Portada_Mobile.svg');
		&-pueda {
			background-image: url('/assets/images/banner-pueda-mobile.svg');
			margin-top: -9em;
			margin-left: 0;
			width: 85%;
			margin-bottom: 40px;
			margin-left: 67px;
		}
		&-background-pueda {
			background-image: url('/assets/images/Accesibilidad_ADC_Portada_Mobile_PUEDA.svg');
		}
	}
	.zoom-buttons-container {
		display: flex;
		flex-direction: row;
		margin: 0 0 20px;
	}

	.info-grid-item {
		font-size: 27px;
	}
	.icon-info-grid {
		width: 100px;
		height: 100px;
	}
	.nav-pills .nav-link, .nav-pills .show>.nav-link {
		font-size: 14px;
		border-radius: 7px;
		line-height: 15px;
		margin: 0px;
		margin-left: -15px;
		margin-right: -10px;
		display: flex;
		justify-content: center;
		display: inline-block;
		margin-bottom: 1em;
	}

	.nav-link{
		padding: 5px 3px;
		border-radius: 5px;
	}

	.video-container{
		width: 100%;
	}

	.fixed-width {
		min-width: fit-content !important;
	}

	.scrollspy{
		border-radius: 5px;
		border: none;
		width: inherit;
		font-size: 14px;
		padding: 5px 15px;
		margin-right: 5px;
		min-width: 150px;
	}

	.col-scrollspy{
		display: flex;
		justify-content: center;
	}

	.icon {
		&-imagenes{
			margin-right: 10px;
		}
		&-guias{
			margin-right: 0px;
		}
		&-videos{
			margin-right: 0px;
		}
	}

	.scrollspy {
		&-row{
			margin-left: 0px;
			padding-left: 0px;
		}

		&-title{
			display: flex;
			align-items: center;
			margin-left: 7%;
			margin-right: 0em;
		}
	}

	.tab-content{
		padding: 0px;
		margin-left: 3px;
		margin-right: -3px;
		&-container{
			margin-left: 0em;
			padding: 0px;
			width: 100%;
		}
	}

	.inside-tab-content{
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 1px;
	}

	.tab-pane{
		padding: 0px;
		margin-right: 7%;
		margin-left: 7%;
	}


	.text-mobile{
		margin-right: 10px !important;
	}

	p {
		margin-right: 0px !important;
		margin-left: 0px !important;
	}

	.carousel {
		&-button{
			display: inline-block;
			background: #5E6884;
			overflow: hidden;
			text-indent: -999px;
			border-radius: 100%;
			width: auto;
			height: 40px;
			padding: 0 10px;
			box-shadow: inset 0 1px 1px 0 #5E6884;
			&.active{
				background-color: #0078D2;
				box-shadow: inset 0 1px 1px 0 #5E6884;
			}
			&:hover{
				background: #0078D2;
			}
		}

		&-inner img {
			max-width: 100%;
		}
	}
	#carouselPuedaVideos {
		.carousel {
			&-button{
				display: inline-block;
				background: inherit;
				overflow: inherit;
				text-indent: initial;
				width: auto;
				height: 55px;
				box-shadow: inset 0 1px 1px 0 #5E6884;
				border-radius: 50px;
				background-color: rgb(234, 247, 253);
				padding: 7px 18px;
				white-space: nowrap;
				font-family: "Open Sans", sans-serif;
				font-size: 15px;
				text-decoration: none;
				color: rgb(63, 72, 93);
				margin: -5px;
				font-size: 0;
				&:before {
					font-size: 15px;
					display: inline;
					position: relative;
				}
				&:nth-of-type(1):before {
					content: 'LSA';
				}
				&:nth-of-type(2):before {
					content: 'LSCH';
				}
				&:nth-of-type(3):before {
					content: 'LSU';
				}
				&.active{
					background-color: rgb(0, 120, 210);
					box-shadow: inset 0 1px 1px 0 #5E6884;
					color: rgb(255, 255, 255);
					&:before {
						background-color: rgb(0, 120, 210);
					}
				}
				&:hover{
					background: #0078D2;
					color: rgb(255, 255, 255);
				}
			}
	}
}
	.center-mobile{
		margin-left: 0em;
	}

	.margin-body{
		margin-left: 0px;
	}

	.col-mobile{
		padding-right: 0px;
		padding-left: 0px;
	}

	.container-dark-grey-background{
		padding-right: 0px;
	}

	.container-light-grey-background{
		padding-right: 0px;
	}

	.text-inside-grey-container{
		margin: 0px;
	}

	.max-width{
		flex: 1 0 100%;
	}
	.info-container .card-info,
	.info-container .div{
		margin: 0px;
	}

	.info-container .card-info {
		margin-top: 10px;
		padding: 5px 15px;
	}

	.reduce-margin-top{
		margin-top: 5px;
	}


	.carousel-inner-videos{
		max-height: 250px;
	}


	.printer-button-container.grey-background{
		margin-bottom: 10px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.tab-content-container .zoomeable {
		font-size: 25px;
		line-height: 30px;
		padding-left: 7px;
	}
	.pueda .info-grid-ro {
		margin-top: 10px;
	}

	#carouselPuedaImages  {
	  .sr-only {
	    position: relative;
	    width: 250px;
	    height: 50px;
	    padding: 0;
	    margin: -1px;
	    clip: rect(0, 0, 0, 0);
	    border: 0;
			color: rgb(0, 120, 210);
	  }
	  .carousel-control {
	    &-next {
	      width: 70px;
	      top: inherit;
	      bottom: 0;
	      display: block;
	      z-index: 99999;
	      right: 20px;
				color: rgb(0, 120, 210);
				opacity: 1 !important;
				&:active,
	      &:focus,
	      &:hover {
	        color: rgb(0, 120, 210);
	      }
	    }
	    &-prev {
	      width: 70px;
	      top: inherit;
	      bottom: 0;
	      display: block;
	      z-index: 99999;
	      left: 10px;
				color: rgb(0, 120, 210);
				opacity: 1 !important;
	      &:focus,
	      &:hover {
	        color: rgb(0, 120, 210);
	      }
	    }
	  }
	}
}
