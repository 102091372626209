.image {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  & .fit {
    display: block;
    width: 100%;
  }
  & .featured {
    display: block;
    width: 100%;
    margin: 0 0 2em 0;
  }
  & .left {
    float: left;
    margin: 0 2em 2em 0;
  }
  & .centered {
    display: block;
    margin: 0 0 2em 0;
    img {
      margin: 0 auto;
      width: auto;
    }
  }
}

/* iframe*/
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: 1.2em;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
