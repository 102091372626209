ul {
  list-style: none;
  &.divided {
    list-style: none;
    padding-left: 0;
    > li {
      border-top: solid 2px #e7eae8;
      padding-top: 2.35em;
      margin-top: 2.35em;
      padding-left: 0;
      &:first-child {
        border-top: 0;
        padding-top: 0;
        margin-top: 0;
      }
    }
    }
  &.actions {
    list-style: none;
    padding-left: 0;
    text-align: center;
    margin: 2em 0 0 0;
    li {
      display: inline-block;
      margin: 0 0 0 1em;
      padding-left: 0;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &.special {
    list-style: none;
    padding-left: 0;
    cursor: default;
    position: relative;
    margin: -2em 0 4em -2em;
    li {
      -moz-transition: top .15s ease-in-out;
      -webkit-transition: top .15s ease-in-out;
      -ms-transition: top .15s ease-in-out;
      transition: top .15s ease-in-out;
      position: relative;
      display: inline-block;
      top: 0;
      padding: 2em 0 0 2em;
      a {
        display: block;
        outline: 0;
        &:before {
          -moz-transition: background-color .2s ease-in-out;
          -webkit-transition: background-color .2s ease-in-out;
          -ms-transition: background-color .2s ease-in-out;
          transition: background-color .2s ease-in-out;
          display: block;
          text-align: center;
          background: #c1cac5;
          border-radius: 100%;
          width: 2.25em;
          height: 2.25em;
          line-height: 2.175em;
          font-size: 5em;
          color: #fff;
        }
        &:hover {
          top: -1em;
        }
      }
      &:hover a:before {
        background: #b9d2ce;
      }
    }
  }
  &.meta {
    font-size: 0.85em;
    color: #a1aaa5;
    list-style: none;
    padding-left: 0;
    li {
      position: relative;
      display: inline-block;
      margin-left: 1em;
      padding-left: 0;
      a {
        color: inherit;
        outline: 0;
      }
      &:first-child {
        margin-left: 0;
      }
      &:before {
        opacity: 0.5;
        cursor: default;
        margin-right: 0.5em;
      }
    }
  }


  &.menu {
    list-style: none;
    padding-left: 0;
    li {
      border-left: solid 1px #E7EAE8;
      display: inline-block;
      padding: 0 0 0 0.75em;
      margin: 0 0 0 0.75em;
      &:first-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
}

ol {
  list-style: decimal;
  padding-left: 0em;
  li {
    padding-left: 0.25em;
  }
}

.simplelist-gray {
  ul{
    list-style: none;
    padding-left: 0em;
  }
  li::before{
    color: #5d6884;
    content: "• ";
  }
}
