@media screen and (min-width: 2000px){
	.contenedor-de-botones {
		.button-link {
			width: 410px;
		}
		.btns-2 {
			width: 700px;
		}
	}
}
