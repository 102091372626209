table {
  width: 100%;
  &.default {
    width: 100%;
    tr {
      border-top: solid 2px #E7EAE8;
      &:first-child {
        border-top: 0;
      }
    }
    td {
      padding: 0.5em 1em 0.5em 1em;
    }
    th {
      text-align: left;
      padding: 0.5em 1em 0.5em 1em;
      margin: 0 0 1em 0;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      color: #5d6884;
    }
    thead {
      background: #E7EAE8;
      color: #fff;
    }
  }
}
