@media screen and (max-width: 1280px) {
	.timeline{
		height: 82%;
		left: -44px;
		&::after{
			right: -10%;
		}
		&::before{
			right: -10%;
		}
		&-years{
			left: 6%;
		}
	}

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
		margin-top: -40px;
	}

	.row > * {
		box-sizing: border-box;
	}

	.row.gtr-uniform > * > :last-child {
		margin-bottom: 0;
	}

	.row.aln-left {
		justify-content: flex-start;
	}

	.row.aln-center {
		justify-content: center;
	}

	.row.aln-right {
		justify-content: flex-end;
	}

	.row.aln-top {
		align-items: flex-start;
	}

	.row.aln-middle {
		align-items: center;
	}

	.row.aln-bottom {
		align-items: flex-end;
	}

	.row > .imp-large {
		order: -1;
	}

	.row > .col-1-large {
		width: 8.33333%;
	}

	.row > .off-1-large {
		margin-left: 8.33333%;
	}

	.row > .col-2-large {
		width: 16.66667%;
	}

	.row > .off-2-large {
		margin-left: 16.66667%;
	}

	.row > .col-3-large {
		width: 25%;
	}

	.row > .off-3-large {
		margin-left: 25%;
	}

	.row > .col-4-large {
		width: 33.33333%;
	}

	.row > .off-4-large {
		margin-left: 33.33333%;
	}

	.row > .col-5-large {
		width: 41.66667%;
	}

	.row > .off-5-large {
		margin-left: 41.66667%;
	}

	.row > .col-6-large {
		width: 50%;
	}

	.row > .off-6-large {
		margin-left: 50%;
	}

	.row > .col-7-large {
		width: 58.33333%;
	}

	.row > .off-7-large {
		margin-left: 58.33333%;
	}

	.row > .col-8-large {
		width: 66.66667%;
	}

	.row > .off-8-large {
		margin-left: 66.66667%;
	}

	.row > .col-9-large {
		width: 75%;
	}

	.row > .off-9-large {
		margin-left: 75%;
	}

	.row > .col-10-large {
		width: 83.33333%;
	}

	.row > .off-10-large {
		margin-left: 83.33333%;
	}

	.row > .col-11-large {
		width: 91.66667%;
	}

	.row > .off-11-large {
		margin-left: 91.66667%;
	}

	.row > .col-12-large {
		width: 100%;
	}

	.row > .off-12-large {
		margin-left: 100%;
	}

	.row.gtr-0 {
		margin-top: 0px;
		margin-left: 0px;
	}

	.row.gtr-0 > * {
		padding: 0px 0 0 0px;
	}

	.row.gtr-0.gtr-uniform {
		margin-top: 0px;
	}

	.row.gtr-0.gtr-uniform > * {
		padding-top: 0px;
	}

	.row.gtr-25 {
		margin-top: -10px;
		margin-left: -10px;
	}

	.row.gtr-25 > * {
		padding: 10px 0 0 10px;
	}

	.row.gtr-25.gtr-uniform {
		margin-top: -10px;
	}

	.row.gtr-25.gtr-uniform > * {
		padding-top: 10px;
	}

	.row.gtr-50 {
		margin-top: -20px;
		margin-left: -20px;
	}

	.row.gtr-50 > * {
		padding: 20px 0 0 20px;
	}

	.row.gtr-50.gtr-uniform {
		margin-top: -20px;
	}

	.row.gtr-50.gtr-uniform > * {
		padding-top: 20px;
	}

	.row > * {
		padding: 0px 0 0 0px;
	}

	.row.gtr-uniform {
		margin-top: -40px;
	}

	.row.gtr-uniform > * {
		padding-top: 40px;
	}

	.row.gtr-150 {
		margin-top: -60px;
		margin-left: -60px;
	}

	.row.gtr-150 > * {
		padding: 60px 0 0 60px;
	}

	.row.gtr-150.gtr-uniform {
		margin-top: -60px;
	}

	.row.gtr-150.gtr-uniform > * {
		padding-top: 60px;
	}

	.row.gtr-200 {
		margin-top: -80px;
		margin-left: -80px;
	}

	.row.gtr-200 > * {
		padding: 80px 0 0 80px;
	}

	.row.gtr-200.gtr-uniform {
		margin-top: -80px;
	}

	.row.gtr-200.gtr-uniform > * {
		padding-top: 80px;
	}


	.map-container {
		.row {
			display: flex;
			&-9 {
				display: flex;
			}
			&-3 {
				display: flex;
			}
		}
	}
	.card-info {
		.col {
			&-7{
				display: inline-block;
			}
			&-5{
				display: inline-block;
			}
		}
	}

	/*zoom buttons*/
	.zoom {
		&-btn{
			width: 2.4em;
			height: 2.4em;
			margin: 0.2em;
			border: 0.15em solid #5d6884;
			border-radius: 25%;
			font-family: 'Open Sans Bold', sans-serif;
			font-weight: 800;
			font-size: 12pt;
			color: #5d6884;
			float: left;
		}
		&-buttons-container{
			margin: 0;
			margin-left: 10px;
		}
	}

	h1 {
		font-family: 'Work Sans';
		font-size: 50px;
		line-height: 60px;
		margin-bottom: 0em;
		text-align: left;
		margin-left: 0em;
		font-weight: 900;
	}
	.subtitle{
		margin-top: 1em;
		font-size: 30px;
		line-height: 36px;
		font-weight: 600;
		margin-bottom: 0.5em;
	}
	h2{
		margin-top: 2em;
		font-size: 30px;
		line-height: 36px;
		font-weight: 600;
		margin-bottom: 0.5em;
	}
	p{
		font-size: 17px;
		margin-right: 2em;
	}
	.row{
		margin-top: 0;
		padding: 0px 14% 0 14%;
	}
	.col-12{
		padding-left: 0;
		padding-right: 1em;
	}
	.col-11{
		/* margin-top: 2em; */
		padding-top: 0;
	}

	.container-lightblue-background ul{
		font-size: 17px;
		line-height: 22px;
	}
	.card-info {
		p{
		 font-size: 17px;
		 line-height: 22px;
	 }
	 li{
		 font-size: 17px;
		 line-height: 22px;
	 }
	}
	.highlight-text-simplelist li{
		font-size: 17px;
		line-height: 22px;
		padding: 2px;
	}
	.highlight-text-container{
		margin-bottom: 1em;
		margin-right: 2em;
	}
	ol li{
		font-size: 17px;
		line-height: 22px;
	}
	.info-container {
		button{
			font-size: 15px;
			line-height: 22px;
		}
		p{
			font-size: 15px;
			line-height: 22px;
			margin-top: 1em;
		}
	}
	.container-lightblue-background{
		margin-bottom: 0;
		padding: 1em 2em;
	}
	.card-info {
		.map-info-text .blue-text{
			font-size: 22px;
			line-height: 26px;
		}
		.map-info-text .lightblue-text{
			font-size: 22px;
			line-height: 26px;
		}
	}
	.highlight-text {
		padding: 0.1em;
		margin-top: 1em;
		margin-bottom: 1.3em;
	}
	#main{
		padding-top: 0;
		border-top-width: 20px;
		border-bottom-width: 10px;
	}
	/* redirect buttons */
	.redirect {
		&-button {
			&-label{
				font-size: 18px;
				line-height: 24px;
			}
			&-image{
				width: 75%;
				padding: 0;
			}
			&-container{
				padding: 0;
				margin-right: 0;
				width: 270px;
				margin-top: 3em;
			}
		}
		&-buttons {
			&-container {
				display: flex;
				flex-direction: row;
				&-2-btns {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					width: 90%;
					margin-top: 3em;
				}
			}
		}
	}

	.button {
		&-la-accesibilidad-web{
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			padding-left: 4em;
			padding-right: 4em;
			display: flex;
			-moz-padding-end: 52px;
			&-2-btns{
				padding-top: 0.4em;
				padding-bottom: 0.4em;
				padding-left: 4em;
				padding-right: 4em;
				display: flex;
				width: 89%;
			}
		}
		&-como-se-investigo{
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			padding-left: 5em;
			padding-right: 5em;
			display: flex;
			-moz-padding-end: 65px;
			&-2-btns{
				padding-top: 0.5em;
				padding-bottom: 0.5em;
				padding-left: 5em;
				padding-right: 5em;
				display: flex;
				width: 85%;
				-moz-padding-end: 62px;
			}
		}
		&-beneficios-y-recomendaciones{
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			padding-left: 5em;
			padding-right: 5em;
			display: flex;
			-moz-padding-end: 62px;
			&-2-btns{
				padding-top: 0.3em;
				padding-bottom: 0.3em;
				padding-left: 5em;
				padding-right: 5em;
				display: flex;
				width: 85%;
				-moz-padding-end: 62px;
			}
		}

	}

	.container-lightblue-background li{
		font-size: 13.5px;
		line-height: 22px;
	}

	/* Basic */

	body, input, textarea, select {
		font-size: 11pt;
	}

	/* Special */

	ul.special li a:before {
		font-size: 60px;
	}

	.box {
		&.highlight {
			h2 {
				font-size: 40px;
			}
			header > p {
				font-size: 1.25em;
			}
		}
		&.post {
			h3 {
				font-size: 2.5em;
			}
			header > p {
				font-size: 1.25em;
			}
		}
	}

	#header {
		font-size: 1.5em;
	}

	#banner {
		padding: 6em 0;
		.content {
			padding: 0 0 3em 0;
			h2 {
				margin: 0 0 0.65em 0;
			}

			p {
				margin: 0 0 1em 0;
			}
		}
		&-pueda {
			margin-bottom: 80px;
			margin-left: 15%;
			margin-top: -9em;
		}
	}

	.zoom-buttons-container {
		flex-direction: column;
	}


}
