@media screen and (min-width: 1550px){
	.contenedor-de-botones {
		.button-link {
			width: 300px;
		}
		.btns-2 {
			width: 470px;
		}
	}
}
