#footer {
  font-family: "Open Sans", sans-serif;
  background: #eaecf6;
  line-height: 20px;
  color: #5d6884;
  font-weight: 400;
  box-shadow: 0 2px 0 rgba(0,0,0,0.3);
  padding: 0;
  margin-bottom: 1px;
  margin: 0em;
  font-size: 16px;
  text-align: center;
  ul {
    margin-left: 19%;
    margin-right: 19%;
    z-index: 1001;
    width: 100%;
    flex-direction: row;
    align-content: space-around;
  }
  li {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    display: inline-block;
    vertical-align: middle;
    -moz-transition: top .15s ease-in-out;
    -webkit-transition: top .15s ease-in-out;
    -o-transition: top .15s ease-in-out;
    -ms-transition: top .15s ease-in-out;
    transition: top .15s ease-in-out;
    margin: 0em 0em 0em 0em;
    width: 27%;
    text-align: left;
    &:first-child {
      padding-top: 30px;
      padding-right: 6px;
    }
    &:last-child {
      width: 60%
    }
    span {
      color:#3f485d;
      width: 40em;
      font-size: 12px;
      line-height: 18px;
      font-stretch: extra-condensed;
      font-weight: unset;
    }
    > ul {
      display: none;
    }
  }
  .logo {
    width: 100%;
    margin-right: 50px;
  }
  .footer {
    &-container {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      font-size: 16px;
      line-height: 21px;
    }
    &-logo-container {
      width: 10%;
      padding-top: 0.9em;
    }
  }
  .copyright {
    padding: 1.5em 0 0.4em 0;
    text-align: center;
    background-color: #f2f4f9;
    padding-left: 14%;
    padding-right: 14%;
    p {
      margin: 0.2em;
      font-size: 16px;
      text-align: center;
      line-height: 21px;
    }
  }
}
