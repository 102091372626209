.icon {
  text-decoration: none;
  position: relative;
  text-decoration: none;
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: inherit;
    text-transform: none !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
  }
  > .label {
    display: none;
  }
  &.solid:before {
    font-weight: 900;
  }
  &.brands:before {
    font-family: 'Font Awesome 5 Brands';
  }
  &-info-grid{
    height: 100px;
    width: 100px;
    display: inline-block;
    margin: 10px 20px 10px 10px;
    background-repeat: no-repeat !important;
  }
  &-acceso-cultura,
  &-vida-independiente,
  &-acceso-informacion,
  &-participacion-ciudadana {
    background-position: center;
  }
  &-participacion-ciudadana{
    background: url('/assets/images/participación_ciudadana_PUEDA.svg');
  }
  &-acceso-informacion{
    background: url('/assets/images/acceso_a_la_información_PUEDA.svg');
  }
  &-vida-independiente{
    background: url('/assets/images/vida_independiente_PUEDA.svg');
  }
  &-acceso-cultura{
    background: url('/assets/images/acceso_a_la_cultura_PUEDA.svg');
  }
  &-videos,
  &-videos-blue,
  &-imagenes-blue,
  &-guias-blue,
  &-guias,
  &-imagenes,
  &-referencias {
    background-repeat: no-repeat !important;
    display: inline-block;
  }
  &-videos{
    background: url('/assets/images/slideshow-icons/videos-icon.svg');
    height: 35px;
    width: 35px;
    margin: 10px 10px 0 0;
  }
  &-videos-blue,
  &-imagenes-blue,
  &-guias-blue {
    height: 75px;
    width: 75px;
    margin-right: 10px;
    background-size: 75px 75px;
  }
  &-videos-blue{
    background: url('/assets/images/slideshow-icons/video-icon-blue.svg');
  }
  &-imagenes-blue{
    background: url('/assets/images/slideshow-icons/imagenes-icon-blue.svg');
  }
  &-guias-blue{
    background: url('/assets/images/slideshow-icons/guias-icon-blue.svg');
  }
  &-videos,
  &-guias,
  &-imagenes{
    height: 40px;
    width: 40px;
    margin: 0 10px 0 0;
    background-position: center;
  }
  &-guias{
    background: url('/assets/images/slideshow-icons/guias-icon.svg');
    background-size: 30px 30px;
  }
  &-imagenes{
    background: url('/assets/images/slideshow-icons/imagenes-icon.svg');
    background-size: 40px 40px;
  }
  &-referencias{
    background: url('/assets/images/slideshow-icons/referencia-icon.svg');
    height: 50px;
    width: 50px;
    margin: 0 10px 0 0;
    background-size: 50px 50px;
  }

}
