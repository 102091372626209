@media screen and (max-width: 1380px){
	.zoom-buttons-container{
		margin: 2.3em 0em 0em 0em;
	}

	.redirect {
		&-button {
			&-label{
				font-size: 18px;
				line-height: 24px;
			}
			&-image{
				width: 75%;
				padding: 0;
			}
			&-container{
				padding: 0;
				margin-right: 0;
				width: 270px;
				margin-top: 3em;
			}
		}
		&-buttons {
			&-container {
				display: flex;
				flex-direction: row;
				&-2-btns {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					width: 90%;
					margin-top: 3em;
				}
			}
		}
	}

	.button {
		&-la-accesibilidad-web{
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			padding-left: 4em;
			padding-right: 4em;
			display: flex;
			-moz-padding-end: 52px;
			&-2-btns{
				padding-top: 0.3em;
				padding-bottom: 0.3em;
				padding-left: 6em;
				padding-right: 6em;
				display: flex;
				width: 117%;
				-moz-padding-end: 84px;
			}
		}
		&-como-se-investigo{
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			padding-left: 5em;
			padding-right: 5em;
			display: flex;
			-moz-padding-end: 65px;
			&-2-btns{
				padding-top: 0.5em;
				padding-bottom: 0.5em;
				padding-left: 8em;
				padding-right: 8em;
				display: flex;
				width: 120%;
				-moz-padding-end: 101px;
			}
		}
		&-beneficios-y-recomendaciones{
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			padding-left: 5em;
			padding-right: 5em;
			display: flex;
			-moz-padding-end: 62px;
			&-2-btns{
				padding-top: 0.3em;
				padding-bottom: 0.3em;
				padding-left: 8em;
				padding-right: 8em;
				display: flex;
				width: 120%;
				-moz-padding-end: 101px;
			}
		}
	}

	#nav a{
		font-size: 12px;
	}
}
