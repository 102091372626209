@media screen and (max-width: 1680px){
	.first-col{
		margin-top: -15px;
	}
	.timeline{
		height: 78.5%;
		left: -66px;
		&::after{
			right: -10%;
		}
		&::before{
			right: -10%;
		}
		&-years{
			left: 5.5%;
		}
	}


	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
		margin-top: -50px;
	}

	.row > * {
		box-sizing: border-box;
	}

	.row.gtr-uniform > * > :last-child {
		margin-bottom: 0;
	}

	.row.aln-left {
		justify-content: flex-start;
	}

	.row.aln-center {
		justify-content: center;
	}

	.row.aln-right {
		justify-content: flex-end;
	}

	.row.aln-top {
		align-items: flex-start;
	}

	.row.aln-middle {
		align-items: center;
	}

	.row.aln-bottom {
		align-items: flex-end;
	}

	.row > .imp-xlarge {
		order: -1;
	}

	.row > .col-1-xlarge {
		width: 8.33333%;
	}

	.row > .off-1-xlarge {
		margin-left: 8.33333%;
	}

	.row > .col-2-xlarge {
		width: 16.66667%;
	}

	.row > .off-2-xlarge {
		margin-left: 16.66667%;
	}

	.row > .col-3-xlarge {
		width: 25%;
	}

	.row > .off-3-xlarge {
		margin-left: 25%;
	}

	.row > .col-4-xlarge {
		width: 33.33333%;
	}

	.row > .off-4-xlarge {
		margin-left: 33.33333%;
	}

	.row > .col-5-xlarge {
		width: 41.66667%;
	}

	.row > .off-5-xlarge {
		margin-left: 41.66667%;
	}

	.row > .col-6-xlarge {
		width: 50%;
	}

	.row > .off-6-xlarge {
		margin-left: 50%;
	}

	.row > .col-7-xlarge {
		width: 58.33333%;
	}

	.row > .off-7-xlarge {
		margin-left: 58.33333%;
	}

	.row > .col-8-xlarge {
		width: 66.66667%;
	}

	.row > .off-8-xlarge {
		margin-left: 66.66667%;
	}

	.row > .col-9-xlarge {
		width: 75%;
	}

	.row > .off-9-xlarge {
		margin-left: 75%;
	}

	.row > .col-10-xlarge {
		width: 83.33333%;
	}

	.row > .off-10-xlarge {
		margin-left: 83.33333%;
	}

	.row > .col-11-xlarge {
		width: 91.66667%;
	}

	.row > .off-11-xlarge {
		margin-left: 91.66667%;
	}

	.row > .col-12-xlarge {
		width: 100%;
	}

	.row > .off-12-xlarge {
		margin-left: 100%;
	}

	.row.gtr-0 {
		margin-top: 0px;
		margin-left: 0px;
	}

	.row.gtr-0 > * {
		padding: 0px 0 0 0px;
	}

	.row.gtr-0.gtr-uniform {
		margin-top: 0px;
	}

	.row.gtr-0.gtr-uniform > * {
		padding-top: 0px;
	}

	.row.gtr-25 {
		margin-top: -12.5px;
		margin-left: -12.5px;
	}

	.row.gtr-25 > * {
		padding: 12.5px 0 0 12.5px;
	}

	.row.gtr-25.gtr-uniform {
		margin-top: -12.5px;
	}

	.row.gtr-25.gtr-uniform > * {
		padding-top: 12.5px;
	}

	.row.gtr-50 {
		margin-top: -25px;
		margin-left: -25px;
	}

	.row.gtr-50 > * {
		padding: 25px 0 0 25px;
	}

	.row.gtr-50.gtr-uniform {
		margin-top: -25px;
	}

	.row.gtr-50.gtr-uniform > * {
		padding-top: 25px;
	}

	.row.gtr-uniform {
		margin-top: -50px;
	}

	.row.gtr-uniform > * {
		padding-top: 50px;
	}

	.row.gtr-150 {
		margin-top: -75px;
		margin-left: -75px;
	}

	.row.gtr-150 > * {
		padding: 75px 0 0 75px;
	}

	.row.gtr-150.gtr-uniform {
		margin-top: -75px;
	}

	.row.gtr-150.gtr-uniform > * {
		padding-top: 75px;
	}

	.row.gtr-200 {
		margin-top: -100px;
		margin-left: -100px;
	}

	.row.gtr-200 > * {
		padding: 100px 0 0 100px;
	}

	.row.gtr-200.gtr-uniform {
		margin-top: -100px;
	}

	.row.gtr-200.gtr-uniform > * {
		padding-top: 100px;
	}

	.scrollspy-row.buttons {
		margin-left: 14%;
	}
	.map-container {
		.row {
			display: flex;
			&-9 {
				display: flex;
			}
			&-3 {
				display: flex;
			}
		}
	}
	.card-info .col-7{
		display: inline-block;
	}
	.card-info .col-5{
		display: inline-block;
	}
	#mapa{
		height:505px;
		width:230px;
	}

	#page-wrapper > section {
		margin-bottom: -90px;
	}

	/*zoom buttons*/
	.zoom-btn{
		width: 2.5em;
		height: 2.5em;
		margin: 0.2em;
		border: 0.1em solid #5d6884;
		border-radius: 25%;
		font-family: 'Open Sans Condensed', sans-serif;
		font-weight: 800;
		font-size: 20px;
		color: #5d6884;
		padding-left: 0px;
		margin-left: 0px;
	}

	/* printer button */
	.printer-button-container {
		display: flex;
		padding-top: 3em;
		padding-bottom: 1.75em;
		justify-content: center;
		align-content: center;
		align-self: center;
	}

	/* redirection button*/

	.redirect {
		&-button {
			&-image{
				width: 90%;
				padding: 0;
			}
			&-container{
				padding: 0;
				margin-right: 0;
				height: unset;
				background-color: transparent;
				display: flex;
				flex-direction: column;
				width:330px;
			}
			&-la-accesibilidad-web{
				padding-top: 0.3em;
				padding-bottom: 0.3em;
				padding-left: 3em;
				padding-right: 4em;
				display: flex;
				&-2-btns{
					padding-top: 0.3em;
					padding-bottom: 0.3em;
					padding-left: 7em;
					padding-right: 7em;
					display: flex;
					width: 124%;
					-moz-padding-end: 143px;
				}
			}
			&-como-se-investigo{
				padding-top: 0.5em;
				padding-bottom: 0.5em;
				padding-left: 5em;
				padding-right: 5em;
				display: flex;
				&-2-btns{
					padding-top: 0.3em;
					padding-bottom: 0.3em;
					padding-left: 9em;
					padding-right: 9em;
					display: flex;
					width: 123%;
				}
			}
			&-beneficios-y-recomendaciones{
				padding-top: 0.3em;
				padding-bottom: 0.3em;
				padding-left: 5em;
				padding-right: 5em;
				display: flex;
				&-2-btns{
					padding-top: 0.3em;
					padding-bottom: 0.3em;
					padding-left: 9em;
					padding-right: 9em;
					display: flex;
					width: 122%;
				}
			}
		}
		&-buttons-container {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			margin-top: 5em;
		}
	}
	.redirect-buttons-container-2-btns {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 90%;
		margin-top: 5em;
	}




	h1 {
		font-family: 'Work Sans';
		font-size: 66px;
		line-height: 80px;
		margin-bottom: 0em;
		text-align: left;
		margin-left: 0em;
		font-weight: 900;
	}
	.subtitle{
		font-family: 'Work Sans';
		margin-top: 0;
		font-size: 50px;
		line-height: 60px;
		font-weight: 600;
		margin-bottom: 0.5em;
	}
	h2{
		font-family: 'Work Sans';
		margin-top: 2em;
		font-size: 50px;
		line-height: 60px;
		font-weight: 600;
		margin-bottom: 0.5em;
	}
	p{
		font-family: 'Open Sans';
		font-weight: 400;
		font-size: 17px;
		line-height: 25px;
		margin-right: 2em;
	}
	.row{
		margin-top: 0;
	}
	.col-12{
		padding-left: 0;
		padding-right: 1em;
	}
	.col-11{
		/* margin-top: 2em; */
		padding-top: 0;
	}

	.container-lightblue-background {
		margin-bottom: 0;
		padding: 1em 2em;
		ul{
			font-size: 17px;
			font-family: 'Open Sans';
			font-weight: 400;
			line-height: 22px;
		}
		li {
			font-size: 17px;
			font-family: 'Open Sans';
			font-weight: 400;
			line-height: 22px;
		}
	}
	.card-info {
		p{
			font-family: 'Open Sans';
			font-weight: 400;
			font-size: 17px;
			line-height: 22px;
		}
		li{
			font-family: 'Open Sans';
			font-weight: 400;
			font-size: 17px;
			line-height: 22px;
		}
	}
	.highlight-text {
		padding: 0.1em;
		margin-top: 1em;
		margin-bottom: 1.3em;
		&-simplelist li{
			font-size: 17px;
			line-height: 22px;
			padding: 2px;
		}
		&-text-container{
			margin-bottom: 1em;
			margin-right: 2em;
		}
	}
	ol li{
		font-family: 'Open Sans';
		font-weight: 400;
		font-size: 17px;
		line-height: 22px;
	}
	.info-container {
		button{
			font-family: 'Open Sans';
			font-weight: 400;
			font-size: 17px;
			line-height: 22px;
		}
		p{
			font-family: 'Open Sans';
			font-weight: 400;
			font-size: 17px;
			line-height: 22px;
			margin-top: 1em;
		}
	}
	#main{
		padding-top: 0;
	}
	.redirect-button-label{
		font-size: 24px;
		line-height: 30px;
	}
	.zoom-buttons-container{
		flex-direction: column;
		margin: 0px 0 0px 10px;
	}
	#footer{
		font-size: 12px;
		li {
			font-size: 12px;
		}
	}
	.copyright p span{
		font-size: 12px;
	}
	.card-info .map-info-text {
		.lightblue-text,
		.blue-text{
			font-size: 25px;
			line-height: 30px;
		}
	}


	/* Basic */

	body, input, textarea, select {
		font-size: 12pt;
		line-height: 1.75em;
	}

	.container {
		width: 75em;
	}
	.body-container h1 {
		margin: 0.5em auto 0.5em 7px;
	}

	#banner-pueda {
		margin-bottom: 80px;
	}
	.info-grid-item {
		font-size: 17px;
	}
	.icon-info-grid {
		width: 75px;
		height: 75px;
	}
	.tab-content-container .zoomeable {
		font-size: 17px;
		line-height: 25px;
	}

}
