.redirect {
  &-button {
    &-image {
      align-self: center;
      background: #f2f2f2	;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      padding-left: 4em;
      padding-right: 4em;
    }
    &-label {
      font-family: "Work Sans", sans-serif;
      font-size: 28px;
      font-weight: 700;
      width: 15em;
      text-align: left;
      color: #3f485d;
      margin-left: 0;
      margin-top: 1em;
      line-height: 50.205px;
      text-align: center;
    }
    &-image.grey-background {
      background-color: #EEEEEC !important;
    }
  }
  &-anchor {
    text-decoration:none;
    width: 15em;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}
