#nav {
  display: flex;
  position: initial;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  padding: 1.5em 1em 1.5em 1em;
  text-transform: uppercase;
  cursor: default;
  padding-left: 0;
  padding-right: 0;
  ul {
    z-index: 1001;
    width: 100%;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    white-space: nowrap
  }
  li {
    display: inline-flex;
    vertical-align: middle;
    -moz-transition: top .15s ease-in-out;
    -webkit-transition: top .15s ease-in-out;
    -o-transition: top .15s ease-in-out;
    -ms-transition: top .15s ease-in-out;
    transition: top .15s ease-in-out;
    padding-right: 15px;
    :last-of-type {
      padding-right: 0;
    }
    & > ul {
      display: none;
    }
    a, span {
      -moz-transition: background-color .075s ease-in-out, color .075s ease-in-out;
      -webkit-transition: background-color .075s ease-in-out, color .075s ease-in-out;
      -ms-transition: background-color .075s ease-in-out, color .075s ease-in-out;
      transition: background-color .075s ease-in-out, color .075s ease-in-out;
      position: initial;
      display: block;
      text-decoration: none;
      color: #5d6884;
      top: -6px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      outline: 0;
    }
    &.active {
      text-decoration: underline;
      color: #0078D2;
    }
    &:hover a, &:hover span {
      color: #000;
    }
    &.active a, &.active span {
      background: #0078D2;
      color: #fff;
    }
    &.current:hover a {
      text-decoration: underline;
    }
    &:hover a.btn-blue-rounded {
      background-color: #212529;
      text-decoration: none;
      color: #ffffff;
    }
    a {
      &:focus {
        outline: -webkit-focus-ring-color auto 1px;
      }
      &:hover {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.12);
        cursor: pointer;
      }
      &.btn-blue-rounded {
        border-radius: 4px;
        background-color: #3F485D;
        color: #ffffff;
        text-decoration: none;
        padding: 10px 25px;
        line-height: 1.1;
      }
      &.active {
        color: #0078D2;
        text-decoration: underline;
      }
    }
  }
  .logo {
    width: 100%;
    max-height: 60px;
    margin-right: 0;
    vertical-align: middle;
  }
  #pueda {
    width: 75%;
    white-space: pre-wrap;
    float: right;
    right: 18px;
  }

  &.current a.btn-blue-rounded {
    background-color: #0078D2;
    text-decoration: none;
  }
  .current a {
    text-decoration: underline;
    color: #0078D2;
  }
}
