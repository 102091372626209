@media screen and (min-width: 980px) {
	.timeline{
		&-content-line {
			right: 6.5%;
		}
	}
	.icon {
		&-prev{
			background: url('/assets/images/slideshow-icons/icon-prev.svg');
			height: 55px;
			width: 55px;
			display: inline-block;
			margin: 10px 0 0 0;
			background-repeat: no-repeat !important;
			opacity: 100%;
		}

		&-next{
			background: url('/assets/images/slideshow-icons/icon-next.svg');
			height: 55px;
			width: 55px;
			display: inline-block;
			margin: 10px 0 0 0;
			background-repeat: no-repeat !important;
			opacity: 100%;
		}
	}
	.redirect {
		&-button-label {
			width: inherit;
		}
		&-btn-container-la-accesibilidad-web{
			position: unset;
			left: 0em;
		}
	}
	.scrollspy {
		&-title{
			padding-top: 20px;
		}

		&-row{
			margin-bottom: 30px;
		}
	}
	.offset-xl-1 {
		margin-left: 8.333333%;
	}
	.col-xl-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-xl-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.contenedor-de-botones{
		flex-direction: row;
		.button-link {
			width: 410px;
		}
		.btns-2 {
			width: 250px;
		}
	}
	.button-link .text-redirect-btn {
		font-size: 20px;
	}

	.container {
		width: calc(100% - 100px);
	}
}
