.zoom {
  &-buttons-container {
    float: left;
    margin-left: -10px;
  }
  &-btn {
    z-index: 1;
    position: relative;
    width: 3em;
    height: 3em;
    margin: 0.2em;
    border: 0.1em solid #5d6884;
    border-radius: 25%;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 800;
    font-size: 20px;
    color: #5d6884;
    :hover {
      background-color: #5d6884;
      color: #fff;
    }
  }
}
