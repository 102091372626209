@media screen and (max-width: 680px){
	.timeline {
		left: -2px;
	}
	#map-img{
		margin: 0 auto;
	}
	.card-info .map-info-text {
		margin: 2em 0 2.3em 1em;
	}
	.info-maps-container{
		display: inline;
		justify-content: center;
		align-content: center;
	}
}
