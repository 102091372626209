@media screen and (max-width: 500px){
	.timeline {
		&-years {
			left: 5%;
		}
		&-content-line {
			right: 8%;
			width: max-content;
		}
	}
	.contenedor-de-botones .button-link {
		width: 350px;
	}
	.redirect-button-label {
		width: max-content;
	}
	.first-col {
		margin-left: 0px;
	}

	.pueda .contenedor-de-botones {
		width: 100%;
		margin: 0;
	}
	.nav-pills {
		width: 100%;
		margin-left: calc(6% + 13px);
		margin-right: 0px;
		margin-top: 1em;
	}
	.scrollspy-row.buttons {
		margin-left: 15px;
	}
	.card-info{
		bottom: 2.5em;
		padding: 1em 1em 1em 1em;
	}
	.bars{
		width: 90%;
	}
	@-moz-document url-prefix(){
		.bars img{
			width: 105%;
		}
	}
	/*zoom buttons*/
	.zoom {
		&-btn{
			margin-left: 0em;
			margin-right: 0.3em;
			width: 2.4em;
			height: 2.4em;
			border: 0.1em solid #5d6884;
			font-size: 20px;
		}
		&-buttons-container{
			display: flex;
			flex-direction: row;
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	#nav {
		background-color: #ffff
	}

	/* basic */
	.body-container p {
		margin-right: 1em;
	}

	.col-1 .not-vissible-col{
		display: none;
	}
	.not-vissible-col-redirection-buttons{
		display: none;
	}
	#main{
		padding-top: 0;
	}

	/* titulos + p */
	h1 {
		font-family: 'Work Sans';
		font-size: 50px;
		line-height:59px;
		text-align: left;
		margin-left: 0em;
		font-weight: 900;
	}
	.subtitle{
		margin-top: 1em;
		font-size: 34px;
		line-height: 43px;
		font-weight: 600;
		margin-bottom: 0.5em;
	}
	h2{
		margin-top: 2em;
		font-size: 34px;
		line-height: 43px;
		font-weight: 600;
		margin-bottom: 0.5em;
		padding-left: 0px;
		padding-right: 0px;
	}
	p,.container-lightblue-background li {
		font-size: 20px;
		line-height: 27px;
	}
	.card-info {
		p {
			font-size: 20px;
			line-height: 27px;
		}
		li {
			font-size: 20px;
			line-height: 27px;
		}
	}
	.container-lightblue-background ul{
		font-size: 20px;
		margin: 5px 7px;
	}
	.info-container button{
		font-size: 15px;
		width: 60%;
	}

	/* listas */
	.highlight-text-simplelist li{
		font-size: 20px;
		line-height: 23px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
	}
	ol li{
		font-size: 20px;
		line-height: 23px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
	}
	.highlight-text {
		padding: 0.2em;
	}

	/* redirection buttons */
	.redirect {
		&-button {
			&-label{
				font-size: 33px;
				line-height: 42px;
			}
			&-image {
				align-self: center;
				background: #f2f2f2	;
				height: 9em;
				width: 100%;
			}
		}
	}
.button {
	&-la-accesibilidad-web-2-btns,
	&-la-accesibilidad-web{
		padding-right: 4em;
		padding-left: 4em;
		padding-top: 1em;
		padding-bottom: 1em;
	}

	&-beneficios-y-recomendaciones,
	&-como-se-investigo{
		padding-right: 5.5em;
		padding-left: 5.5em;
		padding-top: 1em;
		padding-bottom: 1em;
	}
	&-beneficios-y-recomendaciones-2-btns,
	&-como-se-investigo-2-btns{
		padding-right: 5.5em;
		padding-left: 5.5em;
		padding-top: 1em;
		padding-bottom: 1em;
		-moz-padding-start: 19%;
	}

}

	.redirect-buttons-container-2-btns{
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 0em;
		padding-bottom: 0em;
		padding-left: 0;
		padding-right: 0;
		margin-right: 0em;
		margin-left: 0em;
		width: 100%;
		height: auto;
		background-color: transparent;
	}


	/* footer */
	#footer{
		font-size: 14.6px;
		line-height: 21px;
		div ul.footer-list-container {
			padding: 0px;
		}

		.logo {
			width: 145px;
		}

		div {
			ul li {
				width: 88%;
				display: -moz-inline-table;
				display: inline-table;
				padding: 15px 0 15px 0;
				margin: 5px;
				text-align: center;
				letter-spacing: 0px;
				&:last-child {
					width: 100%;
					display: -moz-inline-stack;
					display: inline-block;
					margin: 0;
					padding: 20px;
					background-color: #f2f4f9;
				}
			}
		}
		li {
			font-size: 14px;
			line-height: 21px;
		}
	}

	.copyright p span{
		font-size: 14px;
		line-height: 21px;
	}
	#banner-pueda {
		margin-bottom: 20px;
		margin-left: 18px;
	}
	.nav-pills {
		margin-left: 11%;
	}
	.tab-content-container .zoomeable {
		font-size: 20px;
		line-height: 30px;
	}
	.info-grid-item {
		font-size: 20px;
	}
	.icon-info-grid {
		width: 100px;
		height: 100px;
	}
	.timeline-row {
		p:first-of-type {
			margin-top: 40px;
		}
		.container-lightblue-background  p {
			margin-top: 15px;
		}
	}
	#toggleButton-4 {
		top: -10px;
	}
	.info-container li:first-of-type,
	.info-container p:first-of-type {
		margin-top: 30px;
	}
	.pueda .info-container .card-info {
		margin-top: 20px;
	}

	#carouselPuedaImages  {
		.carousel-indicators {
			margin-bottom: 25px;
		}
		.carousel-control {
			&-next {
				float: right;
				bottom: -35px;
			}
			&-prev {
				float: left;
				bottom: -35px;
			}
		}
	}
}
