@media screen and (min-width: 1280px){
	.contenedor-de-botones {
		.button-link {
			width: 250px;
		}
		.btns-2 {
			width: 380px;
		}
	}
	.timeline-content-line {
		right: 5.6%;
	}
	/* redirection button*/

	.redirect-buttons {
		&-container {
			display: flex;
			flex-direction: row;
			margin-top: 5em;
			&-2-btns {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 90%;
				margin-top: 5em;
			}
		}
	}
	.redirect-button {
		&-image{
			width: 90%;
			padding: 0;
	    margin: 0px 5%;
		}
		&-container{
			padding: 0;
			margin-right: 0;
			height: unset;
			background-color: transparent;
			display: flex;
			flex-direction: column;
			width:330px;
		}
	}
	.button {
		&-la-accesibilidad-web{
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			padding-left: 4em;
			padding-right: 4em;
			display: flex;
			-moz-padding-end: 59px;
			&-2-btns{
				padding-top: 0.3em;
				padding-bottom: 0.3em;
				padding-left: 6em;
				padding-right: 6em;
				display: flex;
				width: 120%;
			}
		}
		&-como-se-investigo{
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			padding-left: 5em;
			padding-right: 5em;
			display: flex;
			&-2-btns{
				padding-top: 0.5em;
				padding-bottom: 0.5em;
				padding-left: 8em;
				padding-right: 8em;
				display: flex;
				width: 120%;
			}
		}
		&-beneficios-y-recomendaciones{
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			padding-left: 5em;
			padding-right: 5em;
			display: flex;
			&-2-btns{
				padding-top: 0.3em;
				padding-bottom: 0.3em;
				padding-left: 8em;
				padding-right: 8em;
				display: flex;
				width: 120%;
			}
		}
	}
}
