.info-container{
  position: relative;
  display: block;
  margin: 0;
  div {
    text-align: center;
  }
  .simplelist{
    list-style: none;
    padding-left: 0;
    margin-left: 15px;
    margin-top: 15px;
  }
}

.card-info {
  display: flex;
  position: relative;
  bottom: 1.2em;
  flex-direction: column;
  word-wrap: break-word;
  flex: 1 1 auto;
  padding: 5px 7px;
  background-clip: border-box;
  border: 1px dashed #0078D2;
  border-width: 1px;
  .simplelist {
    ul{
      list-style: none;
      padding-left: 0em;
    }
    li::before{
      color: #5bc5f2;
      content: "• ";
    }
    &-gray {
      ul{
        list-style: none;
        padding-left: 15px;
      }
      li::before{
        color: #5d6884;
        content: "• ";
      }
    }
  }

  p {
    margin: 15px;
  }
  ul {
    -webkit-ext-align: left;
    -moz-text-align: left;
    -o-text-align: left;
    -ms-text-align: left;
    text-align: left;
    margin-top: 20px;
  }
  li {
    font-family: 'Open Sans', serif;
    font-size: 27px;
    line-height: 42px;
    margin-bottom: 20px;
  }
  .map-info-text{
    flex-direction: column;
    margin: 2em 1em 2.3em 1em;
    .blue-text{
      color: #448fd4ff;
      font-family: 'Open Sans Bold', sans-serif;
      font-size: 32px;
      line-height: 50px;
      font-stretch: 30px;
    }
    .lightblue-text{
      font-family: 'Open Sans Bold', sans-serif;
      font-size: 32px;
      line-height: 50px;
      font-stretch: 30px;
      color: #67ccf4ff;
    }
  }
}

.text-lightblue-background{
  text-align: left;
  background: #e8f8fbff;
  padding-left: 0.75%;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 1em;
  .lightblue-bullet{
    background: #e2f2fd;
    text-align: left;
    padding: 1em;
  }
}
