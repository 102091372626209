@media screen and (max-width: 740px) {
	.body-container {
		.puedaExcept {
			padding: 0px 6% 0 6%;
		}
		.puedapueda {
			padding: 0px 6% 0 6%;
			padding-top: 20px;
		}
	}
	.card-info {
		margin-top: 20px;
		padding-top: 20px;
	}
	#banner-pueda {
		margin-bottom: 40px;
		margin-left: 39px;
	}
	.nav-pills {
		margin-left: 10%;
	}
	.bars{
		width: 90%;
	}
	@-moz-document url-prefix(){
		.bars img{
			width: 105%;
		}
	}

	.timeline-col-11{
		max-width: min-content;
		display: contents
	}
	.container {
		width: calc(100% - 40px);
	}
	.map-container {
		.row {
			display: flex;
		}
		.row-9 {
			display: flex;
		}
		.row-3 {
			display: flex;
		}
	}
	.card-info {
		.col-7{
			display: inline;
		}
		.col-5{
			display: unset;
		}
		.col{
			padding: 2em;
			align-content: center;
		}
	}
	#mapa{
		height:279px;
		width:127px;
	}
	/* Basic */
	body, input, textarea, select {
		font-size: 11pt;
	}

	h1 {
		font-family: 'Work Sans';
		font-size: 74px;
		line-height: 91px;
		margin-bottom: 0em;
		text-align: left;
		margin-left: 0em;
		font-weight: 900;
	}
	.subtitle{
		margin-top: 1em;
		font-size: 50.5px;
		line-height: 62px;
		font-weight: 600;
		margin-bottom: 0.5em;
	}
	h2{
		margin-top: 2.5em;
		font-size: 50.5px;
		line-height: 62px;
		font-weight: 600;
		margin-bottom: 0.5em;
	}
	p{
		font-size: 27px;
		margin-right: 0.5em;
	}
	.col-12{
		padding-left: 0;
		padding-right: 1em;
	}
	.col-11{
		padding-top: 0;
	}
	.col-1 .not-vissible-col{
		display: none;
	}
	.not-vissible-col-redirection-buttons{
		display: none;
	}
	.container-lightblue-background{
		margin-right: 0.5em;
		margin-bottom: 2.5em;
		ul{
			font-size: 27px;
		}
	}
	.card-info{
		bottom: 3.5em;
		p{
			font-size: 27px;
		}
		li{
			font-size: 27px;
		}
	}
	.highlight-text-simplelist li{
		font-size: 27px;
	}
	ol li{
		font-size: 27px;
	}
	.info-container {
		button{
			font-size: 26px;
			width: 70%;
			margin-top: 1.3em;
		}
		p{
			margin-top: 1em;
		}
	}


	.highlight-text {
		padding: 0;
		margin-top: 1em;
		margin-bottom: 1.3em;
	}
	#main{
		padding-top: 0;
	}
	/* redirect buttons */
	.redirect-button-label{
		font-size: 48px;
	}
	.button {
		&-la-accesibilidad-web-2-btns,
		&-la-accesibilidad-web{
			padding-right: 8em;
			padding-left: 8em;
			padding-top: 2em;
			padding-bottom: 2em;
		}

		&-como-se-investigo-2-btns,
		&-como-se-investigo{
			padding-right: 9.6em;
			padding-left: 9.6em;
			padding-top: 2em;
			padding-bottom: 2em;
		}
		&-beneficios-y-recomendaciones-2-btns,
		&-beneficios-y-recomendaciones{
			padding-right: 9.5em;
			padding-left: 9.5em;
			padding-top: 2em;
			padding-bottom: 2em;
		}
	}

	#footer{
		font-size: 23px;
		.logo {
			width: 100px;
		}
	}

	section, article {
		margin-bottom: 2em;
	}

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		width: 100%;
	}
	.carousel-indicators {
		.button {
			width: inherit;
		}
	}

	input[type="button"].large,
	input[type="submit"].large,
	input[type="reset"].large,
	button.large,
	.button.large {
		font-size: 1.25em;
	}

	ul {
		&.actions li {
			display: block;
			margin: 1em 0 0 0;
			&:first-child {
				margin-top: 0;
			}
		}
		&.special {
			margin: -0.75em 0 2em -0.75em;
			li {
				padding: 0.75em 0 0 0.75em;
				a:before {
					font-size: 28px;
				}
			}
		}
	}

	/* Box */

	.box {
		&.highlight {
			h2 {
			 font-size: 40px;
		 }
		 header > p {
			 font-size: 1.25em;
		 }
		}

		&.post {
			h3 {
				font-size: 2em;
			}
			header > p {
				font-size: 1.25em;
			}
		}

		&.page-content{
			h2 {
			 font-size: 2em;
		 }
		 header > p {
			 font-size: 1.25em;
		 }
		}
	}


	/* Banner */

	#banner {
		height: 36em;
			.content {
				padding: 4em 2.5em 3em 2.5em;
			}

			.content h2 {
				font-size: 2em;
			}

			.content p {
				font-size: 1.25em;
			}
	}


	/* Sidebar */

	.sidebar {
		border-top: solid 2px #e7eae8;
		padding-top: 50px;
		margin-top: 30px;
	}

	/* Copyright */

	#copyright .menu li {
		display: block;
		border-left: 0;
		padding-left: 0;
		line-height: 1.75em;
	}

	.timeline-years {
		left: 6%;
	}
	.timeline-content-line {
		right: 9.3%;
	}

	.row {
		margin-top: -20px;
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
		> * {
			box-sizing: border-box;
		}
	}


	.row.gtr-uniform > * > :last-child {
		margin-bottom: 0;
	}

	.row.aln-left {
		justify-content: flex-start;
	}

	.row.aln-center {
		justify-content: center;
	}

	.row.aln-right {
		justify-content: flex-end;
	}

	.row.aln-top {
		align-items: flex-start;
	}

	.row.aln-middle {
		align-items: center;
	}

	.row.aln-bottom {
		align-items: flex-end;
	}

	.row > .imp-small {
		order: -1;
	}

	.row > .col-1-small {
		width: 8.33333%;
	}

	.row > .off-1-small {
		margin-left: 8.33333%;
	}

	.row > .col-2-small {
		width: 16.66667%;
	}

	.row > .off-2-small {
		margin-left: 16.66667%;
	}

	.row > .col-3-small {
		width: 25%;
	}

	.row > .off-3-small {
		margin-left: 25%;
	}

	.row > .col-4-small {
		width: 33.33333%;
	}

	.row > .off-4-small {
		margin-left: 33.33333%;
	}

	.row > .col-5-small {
		width: 41.66667%;
	}

	.row > .off-5-small {
		margin-left: 41.66667%;
	}

	.row > .col-6-small {
		width: 50%;
	}

	.row > .off-6-small {
		margin-left: 50%;
	}

	.row > .col-7-small {
		width: 58.33333%;
	}

	.row > .off-7-small {
		margin-left: 58.33333%;
	}

	.row > .col-8-small {
		width: 66.66667%;
	}

	.row > .off-8-small {
		margin-left: 66.66667%;
	}

	.row > .col-9-small {
		width: 75%;
	}

	.row > .off-9-small {
		margin-left: 75%;
	}

	.row > .col-10-small {
		width: 83.33333%;
	}

	.row > .off-10-small {
		margin-left: 83.33333%;
	}

	.row > .col-11-small {
		width: 91.66667%;
	}

	.row > .off-11-small {
		margin-left: 91.66667%;
	}

	.row > .col-12-small {
		width: 100%;
	}

	.row > .off-12-small {
		margin-left: 100%;
	}

	.row.gtr-0 {
		margin-top: 0px;
		margin-left: 0px;
	}

	.row.gtr-0 > * {
		padding: 0px 0 0 0px;
	}

	.row.gtr-0.gtr-uniform {
		margin-top: 0px;
	}

	.row.gtr-0.gtr-uniform > * {
		padding-top: 0px;
	}

	.row.gtr-25 {
		margin-top: -5px;
		margin-left: -5px;
	}

	.row.gtr-25 > * {
		padding: 5px 0 0 5px;
	}

	.row.gtr-25.gtr-uniform {
		margin-top: -5px;
	}

	.row.gtr-25.gtr-uniform > * {
		padding-top: 5px;
	}

	.row.gtr-50 {
		margin-top: -10px;
		margin-left: -10px;
	}

	.row.gtr-50 > * {
		padding: 10px 0 0 10px;
	}

	.row.gtr-50.gtr-uniform {
		margin-top: -10px;
	}

	.row.gtr-50.gtr-uniform > * {
		padding-top: 10px;
	}

	.row > * {
		padding: 20px 0 0 0;
	}
	.info-grid-item {
		font-size:20px;
	}

	.row.gtr-uniform {
		margin-top: -20px;
	}

	.row.gtr-uniform > * {
		padding-top: 20px;
	}

	.row.gtr-150 {
		margin-top: -30px;
		margin-left: -30px;
	}

	.row.gtr-150 > * {
		padding: 30px 0 0 30px;
	}

	.row.gtr-150.gtr-uniform {
		margin-top: -30px;
	}

	.row.gtr-150.gtr-uniform > * {
		padding-top: 30px;
	}

	.row.gtr-200 {
		margin-top: -40px;
		margin-left: -40px;
	}

	.row.gtr-200 > * {
		padding: 40px 0 0 40px;
	}

	.row.gtr-200.gtr-uniform {
		margin-top: -40px;
	}

	.row.gtr-200.gtr-uniform > * {
		padding-top: 40px;
	}
	.redirect-btn-container-la-accesibilidad-web{
		position: relative;
		left: 1em;
	}
	.icon-info-grid {
		height: 75px;
		width: 75px;
		margin: 10px 20px 10px 10px;
	}
	.pueda .info-container .card-info {
		margin-top: 25px;
	}

}
