@media screen and (min-width: 1680px){
	.first-col{
		margin-top:15px;
	}
	.timeline-years {
		left: 3%;
	}
	.button {
		&-beneficios-y-recomendaciones-2-btns{
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			padding-left: 11em;
			padding-right: 11em;
			display: flex;
			width: 135%;
			-moz-padding-end: 152px;
		}
		&-como-se-investigo-2-btns{
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			padding-left: 11em;
			padding-right: 11em;
			display: flex;
			width: 135%;
			-moz-padding-end: 152px;
		}
		&-la-accesibilidad-web-2-btns{
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			padding-left: 8.7em;
			padding-right: 8.7em;
			display: flex;
			width: 134.6%;
		}
	}
	.timeline-content-line {
		right: 4.5%;
	}
	.zoom-buttons-container {
		display: flex;
		flex-direction: column;
		margin: 40px 0 20px 10px;
	}
	#banner-pueda {
		margin-bottom: 80px;
	}
	.carousel-button {
		border-radius: 50px;
		background-color: #EAF7FD;
		padding: 7px 18px;
		white-space: nowrap;
		font-family: 'Open Sans', sans-serif;
		font-size: 15px;
		text-decoration: none;
		color: #3F485D;
		margin: inherit;
	}
	.contenedor-de-botones {
		.button-link {
			width: 345px;
			.text-redirect-btn {
				font-size: 26px;
			}
		}
		.btns-2 {
			width: 515px;
		}
	}
}
