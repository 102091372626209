.box {
  &.highlight {
    text-align: center;
    h2 {
      font-size: 40px;
      margin: 0 0 0.25em 0;
    }
    header > p {
      font-size: 1.65em;
      margin: 0 0 1.5em 0;
    }
  }
  &.feature {
    text-align: center;
  }
  &.post {
    header {
      margin: 0 0 2.5em 0;
      > p {
        font-size: 1.65em;
        margin: 0 0 0.5em 0;
      }
    }
    h3 {
      font-size: 3em;
      margin: 0 0 0.25em 0;
    }
    ul.meta {
      margin: 0 0 0.5em 0;
    }
    &-summary {
      h3 {
        line-height: 1em;
        margin: 0 0 0.75em 0;
      }
      .meta {
        margin: 0;
        line-height: 1em;
      }
    }
  }
  &.page-content {
    header {
      margin: 0 0 2.5em 0;
      > p {
        font-size: 1.65em;
        margin: 0 0 0.5em 0;
      }
    }
    h2 {
      font-size: 3em;
      margin: 0 0 0.5em 0;
    }
    ul.meta {
      margin: 0 0 0.5em 0;
    }
  }
}
