.timeline {
  display: flex;
  position: relative;
  height: 95%;
  width: 100%;
  border-right: 1px dashed #0078D2;
  margin: 0 auto;
  left: -74px;
  &::after {
    font-family: 'FontAwesome';
    content: '\f111';
    position: absolute;
    font-size: 1em;
    color: #0078D2;
    top: -0.5%;
    right: -6%;
  }
  &::before {
    font-family: 'FontAwesome';
    content: '\f111';
    position: absolute;
    font-size: 1em;
    color: #0078D2;
    bottom: -0.5%;
    right: -6%;
  }
  &-content-right {
    flex-direction: row;
    justify-content: flex-end;
    #year1999 {
      position: relative;
      top: 24em;
    }
    #year2008 {
      position: relative;
      top: 55em;
    }
    #year2018 {
      position: relative;
      top: 92em;
    }
  }
  &-content-line {
    position: relative;
    justify-content: flex-start;
    border-top: 1px dashed #0078D2;
    width: max-content;
    right: 5.4em;
  }
  &-years {
    position: relative;
    left: 5.2em;
    bottom: 0.5em;
    padding: 0.7em;
    border: 1px dashed #0078D2;
    border-radius: 30px;
    border-width: 1px;
    background-color: #fff;
    color: #0078D2;
    font-family: 'Open Sans';
    font-weight: 400;
  }
}
