#header {
  text-align: center;
  font-size: 2em;
  font-family: 'Work Sans', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  padding: 2em 0;
  background: #fab800;
  margin: 0 auto;
  .logo {
    position: relative;
    text-align: center;
    border-top: solid 5px #e7eae8;
    top: 0.65em;
    div {
      background: #fff;
      position: relative;
      display: inline-block;
      padding: 0 1.5em 0 1.5em;
      top: -0.65em;
    }
    h1 {
      display: inline;
    }
    p {
      display: inline;
      color: #C1CAC5;
    }
  }
}
