.dropotron {
  list-style: none;
  background: #6B7770;
  color: #fff;
  border-radius: 6px;
  line-height: 2.75em;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1em 0;
  text-align: left;
  min-width: 14em;
  margin-top: -1em;
  box-shadow: 0 1em 2em 0 rgba(0, 0, 0, 0.1);
  &.level-0 {
    margin-top: 1em;
    font-size: 0.9em;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -1em;
      top: -0.65em;
      /* border-bottom: solid 1em #6B7770; */
      border-left: solid 1em transparent;
      border-right: solid 1em transparent;
    }
  }
  li {
    &:first-child {
      border-top: 0;
    }
    & > a, & > span {
      display: block;
      color: #D7DAD8;
      text-decoration: none;
      padding: 0 1.25em;
    }
    &:hover > a, &:hover > span {
      color: #fff;
      background: #7B8780;
    }
    &.active > a, &.active > span {
      color: #fff;
      background: #7B8780;
    }
  }
}
