@import 'responsive/max/max-width-1680.scss';
@import 'responsive/max/max-width-1490.scss';
@import 'responsive/max/max-width-1380.scss';
@import 'responsive/max/max-width-1280.scss';
@import 'responsive/max/max-width-980.scss';
@import 'responsive/max/max-width-860.scss';
@import 'responsive/max/max-width-740.scss';
@import 'responsive/max/max-width-680.scss';
@import 'responsive/max/max-width-500.scss';
@import 'responsive/max/max-width-425.scss';
@import 'responsive/max/max-width-360.scss';
