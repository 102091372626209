@media screen and (max-width: 360px) {

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
		margin-top: -15px;
		margin-left: 0;
    margin-right: 0;
		.aln-left {
			justify-content: flex-start;
		}

		.aln-center {
			justify-content: center;
		}

		.aln-right {
			justify-content: flex-end;
		}

		.aln-top {
			align-items: flex-start;
		}

		.aln-middle {
			align-items: center;
		}

		.aln-bottom {
			align-items: flex-end;
		}
		> * {
			box-sizing: border-box;
		}
		> .imp-xsmall {
			order: -1;
		}

		> .col-1-xsmall {
			width: 8.33333%;
		}

		> .off-1-xsmall {
			margin-left: 8.33333%;
		}

		> .col-2-xsmall {
			width: 16.66667%;
		}

		> .off-2-xsmall {
			margin-left: 16.66667%;
		}

		> .col-3-xsmall {
			width: 25%;
		}

		> .off-3-xsmall {
			margin-left: 25%;
		}

		> .col-4-xsmall {
			width: 33.33333%;
		}

		> .off-4-xsmall {
			margin-left: 33.33333%;
		}

		> .col-5-xsmall {
			width: 41.66667%;
		}

		> .off-5-xsmall {
			margin-left: 41.66667%;
		}

		> .col-6-xsmall {
			width: 50%;
		}

		> .off-6-xsmall {
			margin-left: 50%;
		}

		> .col-7-xsmall {
			width: 58.33333%;
		}

		> .off-7-xsmall {
			margin-left: 58.33333%;
		}

		> .col-8-xsmall {
			width: 66.66667%;
		}

		> .off-8-xsmall {
			margin-left: 66.66667%;
		}

		> .col-9-xsmall {
			width: 75%;
		}

		> .off-9-xsmall {
			margin-left: 75%;
		}

		> .col-10-xsmall {
			width: 83.33333%;
		}

		> .off-10-xsmall {
			margin-left: 83.33333%;
		}

		> .col-11-xsmall {
			width: 91.66667%;
		}

		> .off-11-xsmall {
			margin-left: 91.66667%;
		}

		> .col-12-xsmall {
			width: 100%;
		}

		> .off-12-xsmall {
			margin-left: 100%;
		}

		&.gtr-0 {
			margin-top: 0px;
			margin-left: 0px;
		}

		&.gtr-0 > * {
			padding: 0px 0 0 0px;
		}

		&.gtr-0.gtr-uniform {
			margin-top: 0px;
		}

		&.gtr-0.gtr-uniform > * {
			padding-top: 0px;
		}

		&.gtr-25 {
			margin-top: -3.75px;
			margin-left: -3.75px;
		}

		&.gtr-25 > * {
			padding: 3.75px 0 0 3.75px;
		}

		&.gtr-25.gtr-uniform {
			margin-top: -3.75px;
		}

		&.gtr-25.gtr-uniform > * {
			padding-top: 3.75px;
		}

		&.gtr-50 {
			margin-top: -7.5px;
			margin-left: -7.5px;
		}

		&.gtr-50 > * {
			padding: 7.5px 0 0 7.5px;
		}

		&.gtr-50.gtr-uniform {
			margin-top: -7.5px;
		}

		&.gtr-50.gtr-uniform > * {
			padding-top: 7.5px;
		}

		&.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		> * {
			padding: 15px 0 0 0;
		}

		&.gtr-uniform {
			margin-top: -15px;
		}

		&.gtr-uniform > * {
			padding-top: 15px;
		}

		&.gtr-150 {
			margin-top: -22.5px;
			margin-left: -22.5px;
		}

		&.gtr-150 > * {
			padding: 22.5px 0 0 22.5px;
		}

		&.gtr-150.gtr-uniform {
			margin-top: -22.5px;
		}

		&.gtr-150.gtr-uniform > * {
			padding-top: 22.5px;
		}

		&.gtr-200 {
			margin-top: -30px;
			margin-left: -30px;
		}

		&.gtr-200 > * {
			padding: 30px 0 0 30px;
		}

		&.gtr-200.gtr-uniform {
			margin-top: -30px;
		}

		&.gtr-200.gtr-uniform > * {
			padding-top: 30px;
		}

	}

	.bars{
		width: 90%;
	}

	@-moz-document url-prefix(){
		.bars img{
			width: 107%;
		}
	}

	.timeline-col-11{
		max-width: min-content;
		display: contents
	}
	.order-list{
		font-size: 12px;
		line-height: 20px;
	}

	p {
		font-size: 12px;
		line-height: 20px;
	}

	.container {
		width: calc(100% - 30px);
		&-list{
			margin-left: 0px;
			margin-right: 0px;
			padding-left: 0px;
			margin-top: 15px;
		}

	}

	.card-info{
		bottom: 2.5em;
		p {
			font-size: 12px;
			line-height: 20px;
			margin:0px;
		}
	}

	.info-container .simplelist{
		margin-left: 0px;
		padding-left: 0px;
	}


	.card-info li {
		font-size: 12px;
		line-height: 20px;
	}

	.map-container {
		.row {
			display: block;
			padding: 0em;
		}
		.row-9 {
			display: grid;

		}
		.row-3 {
			display: grid;
		}
	}
	.card-info {
		.col-5{
			display: unset;
		}
		.col-7{
			display: inline;
		}
		.col{
			padding: 0;
			align-content: center;
		}
	}

	#mapa{
		height:279px;
		width:127px;
	}

	.body-container p {
		margin-right: 2em;
	}

	.zoom {
		&-btn{
			margin-left: 0em;
			margin-right: 0.4em;
			width: 2.5em;
			height: 2.5em;
			border: 0.15em solid #5d6884;
			font-size: 14px;
		}

		&-buttons-container{
			display: flex;
			flex-direction: row;
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	.redirect-buttons-container{
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 0em;
		padding-bottom: 0em;
		padding-left: 0;
		padding-right: 0;
		margin-right: 0em;
		margin-left: 0em;
		width: 100%;
		height: auto;
		background-color: transparent;
		.row{
			margin-left: 0px;
			margin-right: 0px;
		}
		&-2-btns{
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-top: 0em;
			padding-bottom: 0em;
			padding-left: 0;
			padding-right: 0;
			margin-right: 0em;
			margin-left: 0em;
			width: 100%;
			height: auto;
			background-color: transparent;
		}
	}
	.redirect-button-image {
		align-self: center;
		background: #f2f2f2;
		padding:0;
	}

	.button-la-accesibilidad-web,
	.button-como-se-investigo,
	.button-beneficios-y-recomendaciones,
	.button-la-accesibilidad-web-2-btns,
	.button-como-se-investigo-2-btns,
	.button-beneficios-y-recomendaciones-2-btns {
		width: 24em !important;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}

	.printer-button-label{
		margin: 9px;
	}

	body, input, textarea, select {
		font-size: 10pt;
	}

	h1 {
		font-family: 'Work Sans';
		font-size: 40px;
		line-height: 45px;
		margin-bottom: 0em;
		text-align: left;
		margin-left: 0em;
	}
	.subtitle{
		margin-top: 1em;
		font-size: 25px;
		line-height: 30.1px;
		font-weight: 600;
		margin-bottom: 0.5em;
	}
	h2{
		margin-top: 2em;
		font-size: 25px;
		line-height: 37.5px;
		font-weight: 600;
		margin-bottom: 0.5em;
	}
	.col-12{
		padding-left: 0em;
		padding-right: 7px;
	}
	.col-11{
		padding-top: 0;
	}
	.redirect-buttons-col{
		padding-left: 10px;
		padding-right: 10px;
	}
	.not-vissible-col{
		display: none;
	}
	p{
		font-size: 20px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
		line-height: 30px;
	}
	.container-lightblue-background ul{
		font-size: 20px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
	}
	.card-info {
		p{
			font-size: 20px;
			line-height: 30px;
			font-family: 'Open Sans', sans-serif;
			font-weight: 400;
			margin-bottom: 20px;
		}

		li{
			font-size: 20px;
			line-height: 30px;
			font-family: 'Open Sans', sans-serif;
			font-weight: 400;
		}
	}
	.highlight-text-simplelist li{
		font-size: 20px;
		line-height: 30px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
		padding: 0;
	}
	ol li{
		font-size: 20px;
		line-height: 30px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
	}
	.container-lightblue-background{
		margin-right: 0;
		margin-left: 0;
		padding: 1em;
		margin-bottom: 2.5em;
	}

	.highlight-text-container{
		margin-right: 0;
	}

	.info-container{
		margin-top: 1.3em;
		.card-info{
			margin-top: 0.5em;
			margin-bottom: 0.5em;
		}
		button{
			font-size: 18px;
			width: 80%;
		}
	}
	.highlight-text {
		padding: 0.5em;
		margin-top: 1em;
		margin-bottom: 1.3em;
	}
	.card-info {
		margin-top: 10px !important;
	}

	#main{
		padding-top: 0;
		margin: 1em 0px;
	}
	.redirect-button-label{
		font-size: 24px;
		line-height: 30px;
	}

	.container-lightblue-background li {
		font-size: 20px;
		line-height: 30px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
	}

	.card-info .map-info-text .blue-text, .card-info .map-info-text .lightblue-text {
		font-size: 18px;
		font-family: 'Open Sans Bold', sans-serif;
		font-weight: 700;
		line-height: 22px;
	}
	.col-11 .redirect-button-col{
		margin-right: 0;
	}

	.col-1 .not-vissible-col{
		display: none;
	}
	.not-vissible-col-redirection-buttons{
		display: none;
	}
	#footer {
		p,
		li{
			font-size: 14px;
			line-height: 21px;
		}
		div ul.footer-list-container {
			padding: 0px;
			margin-left: 0em;
			margin-right: 0em;
		}
		.logo {
			width: 145px;
		}
		div ul li:first-child {
			width: 50%;
		}
		div ul li {
			width: 80%;
			display: -moz-inline-table;
			display: inline-table;
			padding: 15px 0 15px 0;
			margin: 5px;
			text-align: center;
			letter-spacing: 0px;
		}
		div ul li:last-child {
			width: 100%;
			display: -moz-inline-stack;
			display: inline-block;
			margin: 0;
			padding: 20px;
			background-color: #f2f4f9
		}
	}

	#footer li,
	.copyright p span{
		font-size: 14px !important;
		line-height: 21px !important;
	}
	.col-1 .vissible-col{
		display: flex;
		flex-direction: row;
		width: 25%;
		max-width: none;
	}
	.scrollspy {
		width: fit-content;
  	margin-bottom: 0px;
		&-row {
			flex-direction: column;
			.col {
				margin-bottom: 20px;
			}
			.col-scrollspy:last-child {
				margin-bottom: 0px;
			}
		}
	}
	.icon-guias-blue {
		height: 50px;
		width: 50px;
		background-size: 50px 50px;
	}
	#banner-pueda {
		margin-bottom: -65px;
		margin-left: 18px;
	}
	.nav-pills {
		margin-left: 12%;
	}
}
