.scrollspy{
  font-family: 'Open Sans', sans-serif;
  font-size: 30px;
  border-radius: 10px;
  text-decoration: none;
  background-color: #FFFFFF;
  color: #5E6884 !important;
  border: 5px solid #5E6884;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 20px 25px;
  &:hover{
    color: #0078D2 !important;
  }
  &-row{
    padding-left: 1.2em;
    margin-bottom: 1.5em;
    margin-top: -2.5em;
  }
  &-icon{
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px #888;
    padding: 0.5em 0.6em;
    background-color: #0078D2;
  }
  &-title{
    font-family: 'Open Sans', sans-serif;
    font-size: 35px;
    display: flex;
    align-items: center;
    margin-left: 14%;
    margin-right: 14%;
  }
}
.col-scrollspy {
  padding: 0px !important;
}
