.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: stretch;
  margin-top: 0;
  margin-left: 0;
  padding: 0px 14% 0 14%;
  > * {
    padding: 0px 0 0 0px;
  }
  &.row-pueda{
    margin-top: -3em;
  }
  &.bg-white{
    margin-bottom: 2em;
  }
  > * {
    box-sizing: border-box;
  }
  &.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }
  &.row.aln-left {
    justify-content: flex-start;
  }
  &.aln-center {
    justify-content: center;
    margin-top: inherit;
  }
  &.aln-right {
    justify-content: flex-end;
  }
  &.aln-top {
    align-items: flex-start;
  }
  &.aln-middle {
    align-items: center;
  }
  &.aln-bottom {
    align-items: flex-end;
  }
  > .imp {
    order: -1;
  }
  > .col-1 {
    width: 8.33333%;
  }
  > .off-1 {
    margin-left: 8.33333%;
  }
  > .col-2 {
    width: 16.66667%;
  }
  > .off-2 {
    margin-left: 16.66667%;
  }
  > .col-3 {
    width: 25%;
  }
  > .off-3 {
    margin-left: 25%;
  }
  > .col-4 {
    width: 33.33333%;
  }
  > .off-4 {
    margin-left: 33.33333%;
  }
  > .col-5 {
    width: 41.66667%;
  }
  > .off-5 {
    margin-left: 41.66667%;
  }
  > .col-6 {
    width: 50%;
  }
  > .off-6 {
    margin-left: 50%;
  }
  > .col-7 {
    width: 58.33333%;
  }
  > .off-7 {
    margin-left: 58.33333%;
  }
  > .col-8 {
    width: 66.66667%;
  }
  > .off-8 {
    margin-left: 66.66667%;
  }
  > .col-9 {
    width: 75%;
  }
  > .off-9 {
    margin-left: 75%;
  }
  > .col-10 {
    width: 83.33333%;
  }
  > .off-10 {
    margin-left: 83.33333%;
  }
  > .col-11 {
    width: 91.66667%;
  }
  > .off-11 {
    margin-left: 91.66667%;
  }
  > .col-12 {
    width: 100%;
  }
  > .off-12 {
    margin-left: 100%;
  }
  &.gtr-0 {
    margin-top: 0px;
    margin-left: 0px;
  }
  &.gtr-0 > * {
    padding: 0px 0 0 0px;
  }
  &.gtr-0.gtr-uniform {
    margin-top: 0px;
  }
  &.gtr-0.gtr-uniform > * {
    padding-top: 0px;
  }
  &.gtr-25 {
    margin-top: -12.5px;
    margin-left: -12.5px;
  }
  &.gtr-25 > * {
    padding: 12.5px 0 0 12.5px;
  }
  &.gtr-25.gtr-uniform {
    margin-top: -12.5px;
  }
  &.gtr-25.gtr-uniform > * {
    padding-top: 12.5px;
  }
  &.gtr-50 {
    margin-top: -25px;
    margin-left: -25px;
  }
  &.gtr-50 > * {
    padding: 25px 0 0 25px;
  }
  &.gtr-50.gtr-uniform {
    margin-top: -25px;
  }
  &.gtr-50.gtr-uniform > * {
    padding-top: 25px;
  }
  &.gtr-uniform {
    margin-top: -50px;
  }
  &.gtr-uniform > * {
    padding-top: 50px;
  }
  &.gtr-150 {
    margin-top: -75px;
    margin-left: -75px;
  }
  &.gtr-150 > * {
    padding: 75px 0 0 75px;
  }
  &.gtr-150.gtr-uniform {
    margin-top: -75px;
  }
  &.gtr-150.gtr-uniform > * {
    padding-top: 75px;
  }
  &.gtr-200 {
    margin-top: -100px;
    margin-left: -100px;
  }
  &.gtr-200 > * {
    padding: 100px 0 0 100px;
  }
  &.gtr-200.gtr-uniform {
    margin-top: -100px;
  }
  &.gtr-200.gtr-uniform > * {
    padding-top: 100px;
  }
  &.container-fluid {
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }
}
