@media screen and (max-width: 1490px) {
	.body-container {
		.puedapueda {
			padding-right: 14%;
			padding-left: 14%;
		}
		.puedaExcept {
			padding-right: 14%;
			padding-left: 14%;
		}
	}
	.scrollspy-title {
		margin-left: 14%;
		margin-right: 14%;
	}
	.nav-pills {
		margin-left: 14%;
		margin-right: 14%;
	}
	.tab-pane {
		margin-right: 14%;
		margin-left: 14%;
	}
	#banner-pueda {
		margin-bottom: 100px;
		margin-left: 14%;
		margin-top: -9em;
	}
	.button {
		&-beneficios-y-recomendaciones-2-btns{
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			padding-left: 7em;
			padding-right: 7em;
			display: flex;
			width: 98.5%;
		}
		&-como-se-investigo-2-btns{
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			padding-left: 7em;
			padding-right: 7em;
			display: flex;
			width: 98.5%;
		}
		&-la-accesibilidad-web-2-btns{
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			padding-left: 6em;
			padding-right: 6em;
			display: flex;
			width: 100%;
			-moz-padding-end: 98px;
		}
	}
	.timeline{
		height: 79%;
		left: -58px;
		&::after{
			right: -9%;
		}
		&::before{
			right: -9%;
		}
		&-years{
			left: 6%;
		}
	}
	#nav a{
		font-size: 12px;
	}
}
