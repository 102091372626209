@media screen and (max-width: 860px){
	.timeline-content-line{
		right: 8.3%;
	}
	.redirect-button-label {
		width: auto;
	}
	.body-container h1 {
		margin-left: -3px;
	}
	#footer{
		font-size: 23px;
		.logo {
			width: 100px;
		}
	}
}
