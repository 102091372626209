html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu,  output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu,  section {
	display: block;
}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	body {
		-webkit-text-size-adjust: none;
		background: #fff;
		overflow: hidden;
	}
	mark {
		background-color: transparent;
		color: inherit;
	}
	input::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
	input, select, textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
	}
	html {
		box-sizing: border-box;
		overflow-x: hidden;
		line-height: 1;
	}
	*, *:before, *:after {
		box-sizing: inherit;
	}
	body.is-preload *, body.is-preload *:before, body.is-preload *:after {
		-moz-animation: none !important;
		-webkit-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
		-moz-transition: none !important;
		-webkit-transition: none !important;
		-ms-transition: none !important;
		transition: none !important;
	}
	body, input, textarea, select {
		font-family: '', sans-serif;
		font-weight: 400;
		color: #5d6884;
		font-size: 13pt;
		line-height: 2em;
	}
	h1 {
		font-family: 'Work Sans', sans-serif;
		font-weight: 800;
		color: #3f485d;
		line-height: 90px;
		font-size: 93px;
		text-align: left;
	}
	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
		text-decoration: none;
		color: inherit;
	}
	h2 {
		font-family: 'Work Sans', sans-serif;
		font-weight: 600;
		color: #3f485d;
		margin: 2em 0 0.5em 0;
		line-height: 60px;
		font-size: 55px;
		text-align: left;
		&.major {
			position: relative;
			border-top: solid 5px #e7eae8;
			font-size: 1.65em;
			text-align: center;
			margin: 0 0 3em 0;
			top: 0.775em;
			span {
				background: #fff;
				position: relative;
				display: inline-block;
				top: -0.775em;
				padding: 0 1.25em 0 1.25em;
			}
		}
	}
	h3, h4 {
		font-size: 1.25em;
	}
	a {
		color: #006376;
		text-decoration: underline;
		:hover {
			text-decoration: none;
		}
	}
	i, em {
		font-family: 'Open Sans Italic';
	}
	strong i {
		font-family: 'Open Sans Italic Bold';
	}
	i.lightblue-text {
		color: #0078D2;
	}
	br.clear {
		clear: both;
	}
	sub {
		position: relative;
		top: 0.5em;
		font-size: 0.8em;
	}
	sup {
		position: relative;
		top: -0.5em;
		font-size: 0.8em;
	}
	hr {
		border: 0;
		border-top: solid 2px #E7EAE8;
		margin: 0 0 3em 0;
	}
	blockquote {
		border-left: solid 5px #E7EAE8;
		padding: 1em 0 1em 1.5em;
		font-style: italic;
	}
	p {
		font-family: 'Open Sans', sans-serif;
		font-weight: 300;
		text-align: left;
		margin: 1.3em;
		font-size: 32px;
		line-height: 48px;
	}
	p, strong, ol li, .highlight-text-simplelist li, .card-info li, .container-lightblue-background li {
		color: #5d6884;
		font-family: 'Open Sans';
	}
	b, strong {
		color: #5d6884;
		font-family: 'Open Sans Bold';
	}
	ol li{
		font-size: 32px;
		line-height: 50px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
	}

	.title {
		margin-left: 0px;
	}
	.subtitle {
		font-family: 'Work Sans', sans-serif;
		font-weight: 600;
		color: #3f485d;
		margin: 0;
		line-height: 60px;
		font-size: 55px;
		text-align: left;
		margin-left: -3px;
	}
	.inner-subtitle {
		margin-left: 6px;
	}

	.bg-full {
		&-lightgrey {
			position: relative;
			padding: 1em 0 2em 0;
			:before {
				content: '';
				position: absolute;
				top: 0;
				height: 100%;
			}
		}
		&-darkgrey {
			position: relative;
			:before {
				content: '';
				position: absolute;
				top: 0;
				height: 100%;
			}
		}
	}

	.card-info {
		p{
			font-size: 27px;
			line-height: 42px;
			font-family: 'Open Sans', sans-serif;
			font-weight: 400;
		}
		li{
			font-size: 27px;
			line-height: 42px;
			font-family: 'Open Sans', sans-serif;
			font-weight: 400;
		}
		.map-info-text {
			.blue-text{
				color: #448fd4ff;
				font-family: 'Open Sans Bold', sans-serif;
				font-size: 42px;
				line-height: 50px;
				font-stretch: 30px;
			}
			.lightblue-text{
				font-family: 'Open Sans Bold', sans-serif;
				font-size: 42px;
				line-height: 50px;
				font-stretch: 30px;
				color: #67ccf4ff;
			}
		}
	}
	.highlight-text-simplelist li{
		font-size: 32px;
		line-height: 50px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
	}
	.info {
		&-container p{
			margin-top: 1em;
		}
		&-maps-container{
			display: inline-flex;
			justify-content: center;
			align-content: center;
		}
		&-grid-item {
			font-family: 'Open Sans', sans-serif;
			font-size: 32px;
			background-color: #EAF7FD;
			border-radius: 20px;
			padding: 10px 10px !important;
			border: 10px solid #fff;
			display: flex;
			align-items: center;
			justify-content: left;
		}
	}


	#navPanel nav {
		top:80px;
	}

	.info-grid-ro{
		margin-right: -0.6%;
		margin-left: -0.6%;
	}

	.body-container {
		.row {
			padding: 0;

		}
		.row > * {
			padding: 0;
		}
		.puedaExcept,
		.puedapueda {
			padding-left: 14%;
			padding-right: 14%;
		}
	}



	.scrollspy-row.buttons {
		margin-left: 14%;
	}

	#contenedorBotones {
		padding: 50px 0 0 0;
		width: 100%;
		padding-right: 13%;
		padding-left: 13%;
		.col-12 {
			padding-left: 0;
		}
	}

	.nav-pills li {
		padding-left: 0;
	}

	.pueda .bg-full-darkgrey h2 {
		padding: 30px 14% 60px 14%;
		margin-left: 6px;
	}

	.highlight-text-container div {
		margin: 0 0px 0 10px;
	}

	.padding-left-15 {
		padding-left: 9px !important;
	}

	.info-container {
		div {
			text-align: center;
			margin: 0px -5px 0 5px;
		}
		.simplelist {
			list-style: none;
			padding-left: 0;
			margin-left: 15px;
			margin-top: 15px;
		}
	}
	.pueda .info-container div {
		text-align: center;
		margin: 0px 7px 0 5px;
	}
	.info-container,
	.info-grid-ro {
		max-width: 98.3%;
	}
	.pueda .info-container{
		max-width: 100%;
	}

	.offset-xl-1 {
		.info-container div {
			margin: 0px 0px 0 0px;
		}
		.info-container {
			max-width: 97.8%;
		}
	}

	.error {
		text-align: center;
	}
	.bars {
		display: flex;
		flex-direction: column;
		padding: 0;
		width: 96%;
		margin-left: 10px;
		p{
			margin: 0;
		}
		img {
			padding: 0;
			margin: 0;
		}
	}

	.body-container {
		h1 {
			margin: 0.5em auto 0.5em 0px;
		}
		p {
			margin-left: 7px;
			margin-right: 39px;
			margin-top: 15px;
			margin-bottom: 15px;
		}
		a {
			font-weight: 900;
			color: #0078D2;
		}
	}

	section, article {
		margin-bottom: 6em;
	}

	section > :last-child, section:last-child, article > :last-child, article:last-child {
		margin-bottom: 0;
	}

	header > p {
		display: block;
		font-family: 'Open Sans', sans-serif;
		font-weight: 700;
		text-transform: uppercase;
		color: #5d6884;
	}

	.kind-priority-a{
		background-color: #e8f8fbff;
	}

	.simplelist ul{
		list-style: none;
		padding-left: 0;
		margin-left: 0em;
	}
	.container {
		margin: 0 auto;
		max-width: 100%;
		width: 64em;
		&-fluid {
			padding-right: 0;
			padding-left: 0;
		}
		&-dark-grey-background {
			width: 100%;
			padding-left: 0;
			.row{
				padding-left: 0;
			}
		}
		&-light-grey-background{
			background-color: #CFD3E5;
			margin-left: 10px;
			margin: 0;
		}
		&-dark-grey-background{
			background-color: #5E6884;
			padding-right: 2em;
			margin-left: 10px;
			margin: 0;
			margin-top: 20px;
		}
		&-lightblue-background {
			background: #e2f2fd;
			text-align: left;
			padding: 2em;
			text-decoration-color: #546182ff;
			margin-right: 40px;
			margin-left: 10px;
			ul{
				font-family: 'Open Sans', sans-serif;
				text-align: left;
				margin: 1.3em;
				font-size: 32px;
				line-height: 50px;
				font-weight: 400;
			}
			.simplelist li::before{
				color: #5bc5f2;
				content: "• ";
			}
			li {
				font-size: 32px;
				line-height: 50px;
				margin-bottom: 10px;
			}
			&.bottom {
				margin-bottom: -3em;
			}
			&.graylist {
				margin-bottom: 41px;
			}
		}
	}
	.order-list{
		font-family: 'Open Sans', sans-serif;
		font-weight: 300;
		text-align: left;
		margin: 1.3em 43px;
		font-size: 24px;
		line-height: 38px;
	}

	.highlight-text-simplelist{
		list-style: none;
		li{
			display: inline;
			background-color: #e2f2fd;
			text-align: left;
			padding-left: 0.75%;
			font-family: 'Open Sans', sans-serif;
			font-size: 32px;
			line-height: 50px;
			padding: 3.6px;
		}
		li::before{
			color: #5bc5f2;
			content: "• ";
		}
	}
	.body-container .highlight-text {
		display: inline;
		background-color: #e2f2fd;
		margin: 0em;
		padding: 0.1em;
		margin-bottom: 15px;
	}
	.highlight-text-container{
		margin: 0em;
	}

	.grey {
		position: relative;
		background: #CFD3E5;
		:before,
		:after {
			content: "";
			position: absolute;
			background: #CFD3E5;
			top: 0;
			bottom: 0;
			width: 100%;
		}
		:before {
			right: 100%;
		}
		:after {
			left: 100%;
		}
		&-container {
			background: #ffffff;
			height:auto;
			padding-top: 10px;
			margin-top: 10px !important;
			margin-bottom: 1em;
		}
	}

	.text-inside-grey-container {
		margin: 0;
		padding-top: 30px;
		padding-bottom: 15px;
	}

	.col {
		&-12 {
			padding-right: 0em;
			padding-left: 0.4em;
		}
		&-9 {
			padding-right: 0em;
			padding-left: 0.4em;
		}
	}

	.body-container {
		width: 100%;
	}

	#page-wrapper > section {
		margin-bottom: -50px;
	}

	#banner {
		position: relative;
		background-image: url('/assets/images/Accesibilidad_ADC_Portada_Desktop.svg');
		background-position: top left, top left, center center;
		background-repeat: repeat, repeat, no-repeat;
		background-size: auto, auto, cover;
		z-index: 1;
		padding: 12em 0;
		&-background-pueda {
			position: relative;
			background-image: url('/assets/images/Accesibilidad_ADC_Portada_Desktop_PUEDA.svg');
			background-position: top left, top left, center center;
			background-repeat: repeat, repeat, no-repeat;
			background-size: auto, auto, cover;
			padding: 12em 0;
		}
		&-pueda {
			position: relative;
			background-image: url(/assets/images/banner-pueda.svg);
			background-position: left center;
			background-repeat: no-repeat;
			background-size: contain;
			z-index: 1;
			padding: 7.8% 0;
			margin-top: -13em;
			width: 90%;
			margin-left: 15%;
			min-height: 200px;
		}
	}

	#main {
		background: #fff;
		padding: 1em 0 1em 0;
	}

	.sidebar {
		h2.major {
			text-align: left;
			margin: 0 0 1.5em 0;
		}
		h2.major span {
			padding-left: 0;
		}
	}

	#navPanel, #titleBar {
		display: none;
	}

	.tab-content-container{
		width: 100%;
		margin: 0;

		.zoomeable{
			font-size: 28px;
			line-height: 36px;
		}
	}
	.inside-tab-content{
		padding: 15px 2em 1.5em 2em;
		margin-bottom: 1em;
		p{
			margin-top: 15px;
			margin-bottom: 15px;
		}
	}
	.fixed-width {
		width: max-content;
	}

	.printer-button {
		margin-bottom: 0px;
		&:hover {
			text-decoration: none;
		}
	}

	.row-pueda.row {
		padding: 0;
	}

	.break-anywhere {
		line-break: anywhere;
	}
	#pills-imagenes-tab,
	#pills-guias-tab {
		cursor:default;
	}

	.offset-xl-1 .info-container > div {
		margin-left:0;
	}

	*	a {
		text-decoration: none;
		:hover {
			text-decoration: none;
		}
	}
