.contenedor-de-botones{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 80px;
  padding-right: 30px;
  div{
    margin-top: 10px;
  }
  .button-link{
    padding-bottom: 30px;
    width: 225px;
    height: auto;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
.pueda .contenedor-de-botones{
  width: 100%;
  margin: 0;
}

.button-link{
  margin: 0;
  padding: 0;
  font-family: auto;
  font-size: unset;
  line-height: normal;
  background: transparent;
  border: 10px solid #fff;
  text-decoration: none;
  .text-redirect-btn{
    font-family: "Work Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #3f485d;
    text-align: left;
  }
  .text-redirect-btn:hover{
    color: #0078D2;
    cursor: pointer;
  }
}

input[type="button"],
input[type="submit"],
input[type="reset"],
button {
  margin: 0;
  padding: 0;
  font-size: unset;
  line-height: normal;
  background: transparent;
  border: 10px solid #fff;
  -webkit-appearance: button;
  display: inline-block;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  text-transform: none;
  outline: 0;
  cursor: pointer;
  text-align: center;
}
.button {
  &-info {
    z-index: 1;
    position: relative;
    width: 12em;
    height: 2.5em;
    -webkit-appearance: button;
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    text-transform: none;
    background: #fff;
    color: #0078D2;
    border: 0.1em solid #0078D2;
    border-radius: 30px;
    outline: 0;
    cursor: pointer;
    -moz-transition: background-color .2s ease-in-out;
    -webkit-transition: background-color .2s ease-in-out;
    -o-transition: background-color .2s ease-in-out;
    -ms-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out;
    font-size: 1em;
    text-align: center;
    &-close::after {
        font-family: 'FontAwesome';
        content: '\f107';
        font-size: 1.2em;
        margin-left: 0.6em;
        margin-top: -5em;
      }

    &-open::after {
    		font-family: 'FontAwesome';
    		content: '\f106';
    		font-size: 1.2em;
    		margin-left: 0.6em;
    		margin-top: -5em;
    	}
  }
}

button:active,
.button:active,
input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
button:hover,
.button:hover {
  background: #fff;
}

input[type="button"].alt,
input[type="submit"].alt,
input[type="reset"].alt,
button.alt,
.button.alt {
  background: #c1cac5;
}
input[type="button"].alt:hover,
input[type="submit"].alt:hover,
input[type="reset"].alt:hover,
button.alt:hover,
.button.alt:hover {
  background: #ccd5d0;
}
input[type="button"].alt:active,
input[type="submit"].alt:active,
input[type="reset"].alt:active,
button.alt:active,
.button.alt:active {
  background: #b1bab5;
}
input[type="button"].large,
input[type="submit"].large,
input[type="reset"].large,
button.large,
.button.large {
  font-size: 1.65em;
}
