.printer {
  &-button {
    height: unset;
    width: unset;
    align-self: center;
    align-content: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 50px auto;
    text-decoration: none;
    &-image {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      height: 8em;
      width: 15em;
      align-self: center;
    }
    &-label {
      font-family: "Work Sans", sans-serif;
      font-size: 1em;
      font-weight: 700;
      padding: 5px 0;
      text-align: center;
      color: #f2f2f2;
      line-height: inherit;
      margin: 0 auto;
    }
    &-container.grey-background{
      background-color: #EEEEEC;
      margin-bottom: 7px;
      padding-bottom: 45px;
      padding-top: 45px;
    }
    &-pueda:hover{
      background-color: #EEEEEC !important;
    }
    b {
      background: #3f485d;
      text-align: center;
      border-radius: 3em;
      padding: 12px 15px;
    }
  }
  &-anchor {
    text-decoration:none;
    display: flex;
    flex-direction: column;
  }
  &-span {
    align-self: center;
  }
}
