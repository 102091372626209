@import url('https://fonts.googleapis.com/css?family=Open+Sans:400, 700, 800, bold|Work+Sans:600,700,800&display=swap');

@font-face {
	font-family: 'Open Sans Italic';
	font-style: italic;
	font-weight: 400;
	src: local('Open Sans'), local('OpenSans'), url(/assets/fonts/OpenSans-Italic.woff) format('woff');
}

@font-face {
	font-family: 'Open Sans Bold';
	font-style: bold;
	font-weight: 400;
	src: local('Open Sans'), local('OpenSans'), url(/assets/fonts/OpenSans-Bold.woff) format('woff');
}

@font-face {
	font-family: 'Open Sans Italic Bold';
	font-style: bold;
	font-weight: 400;
	src: local('Open Sans'), local('OpenSans'), url(/assets/fonts/OpenSans-Bold-Italic.woff) format('woff'),url(),
	url(/assets/fonts/OpenSans-Bold-Italic.woff2) format('woff2');
}
