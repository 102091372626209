.nav-pills {
  padding-bottom: 1em;
  width: 100%;
  margin-left: 14%;
  margin-right: 13%;
  margin-top: 1em;
  &-videos .nav-item{
    max-width: fit-content;
  }
  .nav-link.active,
  .show>.nav-link {
    background-color: #FFFFFF;
    border: 2px solid #0078D2;
    color: #5E6884;
    padding: 10px 18px;
    letter-spacing: 0.4px;
  }
  .nav-link,
  .show>.nav-link {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #5E6884;
    background-color: #EAECF6;
    border: 2px solid #5E6884;
    border-radius: 12px;
    text-decoration: none;
    margin-bottom: 1em;
    white-space: nowrap;
  }
}
.tab-pane {
  background-color: #fff;
  margin-top: -1em;
  margin-right: 14.7%;
  margin-bottom: 1.5em;
  margin-left: 13.9%;
  min-height: 20em;
  #p {
    margin-top: -0em;
    margin-bottom: 2em;
  }
}
